import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})

@Injectable()
export class DatenschutzComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Datenschutzerklärung - Wenzke-Consulting ☆ Der Schutz deiner Daten liegt mir am ♥ Deine Daten sind bei mir sicher ♥ Verschlüsselung' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'Datenschutzerklärung, Datenschutzrichtlinien' });
    this.title.setTitle("Datenschutzerklärung - Wenzke-Consulting");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");
  }

  ngOnInit() {
    // Scroll to top after link click
    window.scroll(0, 0);
  }

}