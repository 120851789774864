<div class="view withoutBackgroundPicture">

  <div class="container">

    <section class="content-section">

      <!--First row-->
      <div class="row">

        <!--First Column-->
        <div class="col-lg-12">

          <!-- Heading -->
          <h1>Impressum von Wenzke-Consulting.de</h1>

          <div class="well">
            <address>
              <br />
              <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
              <p>Patrick Wenzke<br />
                Patrick Wenzke Consulting<br />
                Professor-Otto-Hupp-Str. 9<br />
                85764 Oberschlei&szlig;heim</p>

              <h2>Kontakt</h2>
              <p><abbr title="Telefonnummer">Tel:</abbr> 08923032664<br />
                <abbr title="Faxnummer">Fax:</abbr> 08923032665<br />
                E-Mail: <a href="mailto:info@wenzke-consulting.de">info&#64;wenzke-consulting.de</a><br />
                PGP: <a href="https://keys.openpgp.org/vks/v1/by-fingerprint/DDB19621661B520DCCE641AAA311D81019F89156" target="_blank">DDB19621661B520DCCE641AAA311D81019F89156</a>
              </p>

              <h2>Redaktionell Verantwortlicher</h2>
              <p>Patrick Wenzke<br />
                Professor-Otto-Hupp-Str.9<br />
                85764 Oberschlei&szlig;heim</p>

            </address>
          </div>

          <h2>EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
              href="https://ec.europa.eu/consumers/odr" target="_blank"
              rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br /> Unsere E-Mail-Adresse finden Sie
            oben im Impressum.</p>

          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.</p>

          <h3>Haftung f&uuml;r Inhalte</h3>
          <p>Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter
            jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder
            nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p>
          <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der
            Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
          <h3>Haftung f&uuml;r Links</h3>
          <p>Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die
            Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
            verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
            &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
          <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.</p>
          <h3>Urheberrecht</h3>
          <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung
            au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen
            Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
            kommerziellen Gebrauch gestattet.</p>
          <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

          <p>Quelle: <a
              href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
          </p>

          <h2>Bildlizenzen für Seiten-Icons und Fotografien</h2>
          <p>Bei den auf diesem Internetauftritt benutzten Bildern handelt es sich um Free for commercial use Grafiken,
            welche dem Creative Commons
            Lizenzvertrag unterliegen.</p>

          <p>Dieses Werk bzw. Inhalt steht unter einer Creative Commons Namensnennung 3.0 Unported Lizenz.</p>

        </div>
        <!--./First Column-->

      </div>
      <!--./First row-->
    </section>

  </div>

</div>