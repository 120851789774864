import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Kontakt } from '../kontakt';
import { HttpClient } from '@angular/common/http';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css']
})

@Injectable()
export class KontaktComponent implements OnInit {

  // properties
  submitted = false;
  endpoint: string = "https://www.wenzke-consulting.de/assets/sendmail.php";
  model = new Kontakt("", "", "");
  successTransport = false;
  errorWhileTransport = false;
  sendError;

  constructor(@Inject(DOCUMENT) private doc,
    private http: HttpClient, private meta: Meta, private title: Title
  ) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Warten Sie nicht lange und kontaktieren Sie mich mit dem Kontaktformular oder über das Telefon - Wenzke-Consulting ☎ Formular zur Kontaktaufnahme' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'Kontaktformular, Kontakt' });
    this.title.setTitle("Kontakt - Ich bin für Sie da! Patrick Wenzke Consulting");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  ngOnInit() {
  }

  processForm() {

    this.submitted = true;

    let postVars = {
      name: this.model.name,
      email: this.model.mail,
      msg: this.model.message
    };

    console.log(postVars);

    this.http.post(this.endpoint, postVars).subscribe(
      (val) => {
        console.log("POST call successful value returned in body",
          val);
      },
      response => {
        console.log("POST call in error", response);
        this.errorWhileTransport = true;
      },
      () => {
        console.log("The POST observable is now completed.");
        this.successTransport = true;
      });

    //TODO: reset form

  }

}
