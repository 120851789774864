<div id="notfound-intro" class="view">


  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Entschuldigung</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h4 class="white-text my-4">Die gewünschte Seite konnte nicht gefunden werden.</h4>

          <button type="button" class="btn btn-outline-white" routerLink="/">Zurück zur Startseite
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>
</div>