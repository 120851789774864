import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-responsive-design',
  templateUrl: './responsive-design.component.html',
  styleUrls: ['./responsive-design.component.css']
})

@Injectable()
export class ResponsiveDesignComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Responsive Design für die perfekte Website auf PC, Smartphone & Tablet ✓ Moderne Technologien' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'Responsive Design,Webdesign' });

    // Open Graph Meta information
    this.meta.addTag({ property: 'og:locale', content: 'de_DE' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: 'Responsive Design - Die perfekte Website auf allen Geräten' });
    this.meta.addTag({ property: 'og:description', content: 'Responsive Design für die perfekte Website auf PC, Smartphone & Tablet ✓ Moderne Technologien' });
    this.meta.addTag({ property: 'og:site_name', content: 'Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.wenzke-consulting.de/responsive-design' });
    this.meta.addTag({ property: 'og:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });

    // Twitter Meta information
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@wenzkeconsult' });
    this.meta.addTag({ name: 'twitter:title', content: 'Responsive Design - Die perfekte Website auf allen Geräten' });
    this.meta.addTag({ name: 'twitter:description', content: 'Responsive Design für die perfekte Website auf PC, Smartphone & Tablet ✓ Moderne Technologien' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });
    
    // Website title
    this.title.setTitle("Responsive Design - Die perfekte Website auf allen Geräten");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  priceBasic: number = 799;
  pricePro: number = 1099;
  pricePremium: number = 1699;

  ngOnInit() {

    // Scroll to top after link click
    window.scroll(0, 0);
  }

  scroll(el) {
    el.scrollIntoView();
  }
  
}
