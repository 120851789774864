<!--Software Entwicklung Intro-->
<div id="sw-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Software-Entwicklung</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h3 class="white-text my-4">Individuelle Programmierung für Sie</h3>
          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.Software Entwicklung Intro-->

<main>

  <div class="entry-wrap pt-5 pb-5">

    <div class="container">

      <section #target>

        <!--First Row-->
        <div class="row wow fadeIn">

          <!--First Column-->
          <div class="col-md-6">
            <h2>Apps für
              <b>Android</b>,
              <b>Windows</b> und
              <b>Linux</b>
            </h2>
            <p>
              Zeigen Sie Ihren Kunden, dass Sie im digitalen Zeitalter angekommen sind.
            </p>
            <ul class="icon-list">
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Ich verwende immer modernste Technologien bei der Entwicklung -
                <i>zukunftsfähig und jederzeit erweiterbar</i>.
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Mein Fokus liegt klar auf Bedienbarkeit -
                <i>Anwender erfreuen sich an innovativer Software</i>.
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Geräteunabhängig auf Smartphones und Desktop PCs -
                <i>Ob unterwegs oder im Büro</i>.
              </li>
            </ul>
            <p>
              Vertrauen Sie auf Patrick Wenzke Consulting. Als Ihr zuverlässiger Partner werde ich Sie mit
              professioneller Windows Software-entwicklung oder Android Software-entwicklung begeistern. Durch die
              Verwendung von <a routerLink="/jira" title="JIRA Software">JIRA Software</a> können Sie jederzeit live
              den aktuellen Status der Umsetzung ansehen.
              Rufen Sie mich an!
            </p>
          </div>
          <!--./First Column-->

          <!--Second Column-->
          <div class="col-md-5 col-md-offset-1">
            <img src="assets/software-entwicklung.jpg" alt="Software-Entwicklung Windows"
              title="Software-Entwicklung Windows" class="img-fluid shadow">
          </div>
          <!--./Second Column-->

        </div>
        <!--./First Row-->

        <!--Second Row-->
        <div class="row wow fadeIn mt-5">

          <!--First Column-->
          <div class="col-lg-6">
            <img src="assets/software-entwicklung-android.jpg" alt="Software-Entwicklung Android"
              title="Software-Entwicklung Android" class="img-fluid shadow">
          </div>
          <!--./First Column-->

          <!--Second Column-->
          <div class="col-lg-6">
            <h2>Individuelle
              <b>Software-Entwicklung</b>
            </h2>
            <p>
              Besondere Anforderungen erfordern eine maßgeschneiderte Lösung. Ihr anspruchsvoller Wunsch wird gerne von
              mir umgesetzt.
              Heben Sie sich von Ihren Mitbewerben ab!
            </p>
            <ul class="icon-list">
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Integration in komplexe Infrastrukturen und Schnittstellen zu Fremdsystemen
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Besondere Herausforderungen an Design und Usability, um die Komplexität im Hintergrund für den Benutzer
                einfach zu machen
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                Zuverlässigkeit, Qualität und Pflegbarkeit stehen im Fokus, da Enterprise Apps business-kritisch sind.
              </li>
            </ul>

          </div>
          <!--./Second Column-->

        </div>
        <!--./Second Row-->

      </section>

    </div>

  </div>

  <div class="container">

    <section class="mt-5 mb-5">

      <!--First Row-->
      <div class="row">

        <!--First Column-->
        <div class="col-lg-12 wow fadeIn">
          <h2>Android Software-Entwicklung</h2>
          <p>
            Android, dass mobile Betriebssystem von Google hat laut der alljährlichen Gartner Analyse inzwischen einen
            Marktanteil von 85,9%.
            Sie haben bereits eine Idee im Kopf für eine schicke und innovative Android App? Sie würden diese gerne
            umsetzen, jedoch fehlt Ihnen das technische
            Know-how? Kein Problem! Gemeinsam mit Ihnen erstellen wir das Konzept und das Design für Ihr Vorhaben und
            setzen es in die Tat um.</p>
          <p>Freuen Sie sich auf Ihre individuelle App, die ihnen einen hohen Wiedererkennungswert verschafft.
            Ich helfe ihnen dabei, die neue App im Google Play Store zu veröffentlichen und
            kümmere mich ggf. um Möglichkeiten zur Monetarisierung. Auf meine Unterstützung können Sie jederzeit bauen.
          </p>
          <h2>Linux Software-Entwicklung</h2>
          <p>
            Ich erstelle auf Ihren Wunsch hin eine Open-Source Anwendung für das quelloffene Betriebssystem Linux.
            Hierbei kann ich auf langjährige Erfahrung mit Linux und Unix zurückgreifen.
            Sie entscheiden, ob es sich hierbei um eine Anwendung mit grafischer Oberfläche handeln soll, oder eine
            Terminal-Anwendung. Von mir entwickelte Skripte helfen Ihnen, bis dato manuell ausgeführte, zyklische
            Aufgaben zu automatisieren.
            Damit sparen Sie Zeit und reduzieren die Fehleranfälligkeit.
          </p>
          <h2>Windows Software-Entwicklung</h2>
          <p>
            Für das weltweit verbreitete Betriebssystem Microsoft Windows erstelle ich Ihre Software objektorientiert
            in der
            Programmiersprache C#.
            Mich zeichnet ein hoher Qualitätanspruch und ein Bewusstsein für die Möglichkeit zur Weiterentwicklung aus.
            Typische Windows Anwendungen lassen sich über eine grafische Oberfläche bedienen.
            Ich lege bei der Windows Software-Entwicklung einen besonders hohen Wert auf eine ansprechende Oberfläche,
            die einfach für
            den Benutzer zu bedienen ist.
            Eine simple Installation der Anwendung, sowie eine gute und leicht verständliche Dokumentation sind für mich
            selbstverständlich.
          </p>
          <p>
            Egal ob es sich bei Ihrem Wunsch um eine Android App, oder ein Anwendung für den normalen PC, oder Server
            handelt,
            ich bin immer für Sie da!
          </p>
        </div>
        <!--./First Column-->

        <!--Second Column-->
        <div class="col-lg-12">

          <div class="card-deck">

            <!--Basic Card-->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.2s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Android</h4>
                <!-- Price -->
                <i class="fab fa-android green-text fa-4x"></i>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Beratung & Konzeption</p>
                  </li>
                  <li>
                    <p>
                      Entwicklung via Java Android-SDK</p>
                  </li>
                  <li>
                    <p>
                      Veröffentlichung im Google Play</p>
                  </li>
                  <li>
                    <p>
                      Weiterentwicklung & Support</p>
                  </li>

                </ul>
              </div>

            </div>
            <!--/.Card-->

            <!--Pro Card-->
            <div class="card pricing-card pricing-card-pro shadow wow slideInUp" data-wow-delay="0.4s">
              <div class="over">

                <!--Card content-->
                <div class="card-body">
                  <!--Title-->
                  <h4 class="card-title font-weight-bold mt-3">Windows</h4>
                  <!-- Price -->
                  <i class="fab fa-windows white-text fa-4x"></i>
                  <!--Text-->

                  <ul class="list-unstyled striped title">
                    <li>
                      <p class="white-text">
                        Windows 7/8/10</p>
                    </li>
                    <li>
                      <p class="white-text">
                        Java & C#</p>
                    </li>
                    <li>
                      <p class="white-text">
                        Objektorientiert
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <!--/.Card-->

            <!--Premium Card-->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Linux</h4>
                <!-- Price -->
                <i class="fab fa-linux indigo-text fa-4x"></i>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Konsolenanwendungen</p>
                  </li>
                  <li>
                    <p>
                      Anwendungen mit grafischer Oberfläche</p>
                  </li>
                  <li>
                    <p>
                      Automatisierungsskripte</p>
                  </li>
                  <li>
                    <p>
                      Unabhängig von Distributionen
                  </li>
                </ul>
              </div>

            </div>
            <!--/.Card-->

          </div>
          <!--./Card Deck-->

          <!-- Call to action -->
          <div class="row">
            <div class="col-md-5 p-lg-3 mx-auto my-3">

              <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
                href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/24"
                target="_blank" title="Marketing Anfrage">Ich brauche einen Entwickler
                <i class="fas fa-arrow-right ml-1"></i>
              </a>

            </div>
          </div>

        </div>
        <!--./Second Column-->

      </div>
      <!--./First Row-->

    </section>

  </div>

</main>