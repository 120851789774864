<!--PWC Intro -->
<div id="intro" class="view">

    <div class="mask rgba-black-strong">

        <div class="container-fluid d-flex align-items-center justify-content-center h-100">

            <div class="row d-flex justify-content-center text-center">

                <div class="col-lg-12">

                    <!-- Heading -->
                    <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Wenzke-Consulting</h1>

                    <!-- Divider -->
                    <hr class="hr-light">

                    <!-- Description -->
                    <h4 class="white-text my-4">Professionelle IT-Dienstleistungen</h4>
                    <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
                        <i class="fas fa-graduation-cap"></i>
                    </button>

                </div>

            </div>

        </div>

    </div>

</div>
<!--/PWC Intro-->

<main>

    <div class="container">

        
        <!--Request Section-->
        <section class="mt-5" #target>

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <h2 class="my-5 h2">Kostenloses Beratungsgespäch vereinbaren</h2>
                    <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
                        routerLink="beratungsgespraech" target="_blank" title="Termin vereinbaren">Termin vereinbaren
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>

                </div>

                
                <!--Grid column-->
                               
                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <h2 class="my-5 h2">Anfrage direkt an unseren ServiceDesk stellen</h2>
                    <a href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1"
                        class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0" target="_blank"
                        title="ServiceDesk verwenden">ServiceDesk verwenden
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>
                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>
        <!--/Request Section-->

        <hr class="my-5">

        <!--JIRA Section-->
        <section class="mt-5" #target>

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <img src="assets/jira-landingpage.jpg" class="img-fluid shadow"
                        alt="JIRA Software für Aufgaben- und Projektmanagement"
                        title="JIRA Software für Aufgaben- und Projektmanagement">

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <!-- Main heading -->
                    <h2 class="h2 mb-2">Jira Software</h2>
                    <p>
                        Ich unterstütze Sie bei der Einführung von <a routerLink="jira" title="Jira Software">Jira
                            Software</a>. Mit Atlassian Jira erleichtern Sie Ihr
                        Projekt- und Aufgabenmanagement grundlegend.
                    </p>
                    <p>Hierbei übernehme ich sowohl die technische Umsetzung als auch die Abbildung Ihrer fachlichen
                        Anforderungen.</p>
                    <p>Gerne berate ich Sie auch hinsichtlich weiterer Atlassian Produkte, wie zum Beispiel Jira
                        Servicemanagement oder nützlicher Plugins.</p>
                    <p>Erhalten Sie mehr Informationen zu JIRA und profitieren sie von den umfangreichen Funktionen
                        der weltweit
                        bekannten Webanwendung.</p>

                    <a class="btn btn-indigo btn-md waves-effect waves-light shadow ml-0" routerLink="jira"
                        title="JIRA">Weiter
                        zu JIRA
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>
                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>
        <!--/JIRA Section-->

        <hr class="my-5">

        <!--Onlineshops Section-->

        <section class="mt-5">

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <!-- Main heading -->
                    <h2 class="h2 mb-2">Onlineshop erstellen lassen</h2>
                    <p>
                        Der Online-Handel in Deutschland boomt. Sie möchten Ihre Produkte auch im Internet verkaufen?
                        Dann wird es höchste Zeit für einen eigenen Onlineshop.
                    </p>
                    <p>Bieten Sie ihren Kunden die Möglichkeit, rund um die Uhr zu bestellen.</p>
                    <p>Gerne berate ich Sie und übernehme die technische Realisierung ihres Onlineshops nach ihren
                        individuellen Wünschen.</p>

                    <a class="btn btn-indigo btn-md waves-effect waves-light shadow ml-0" routerLink="shops"
                        title="Onlineshops">Weiter
                        zu Onlineshops
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <img src="assets/shop-entwicklung.jpg" class="img-fluid shadow"
                        alt="Onlineshop von Wenzke-Consulting erstellen lassen"
                        title="Onlineshop von Wenzke-Consulting erstellen lassen">

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>

        <!--/Onlineshops Section-->

        <hr class="my-5">

        <!--SEO Section-->
        <section>

            <h2 class="my-5 h2 text-center">Google SEO</h2>

            <!--First row-->
            <div class="row features-small mb-5 mt-3 wow fadeIn">

                <!--First column-->
                <div class="col-md-4">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>Werden Sie gefunden</h3>
                            <p class="grey-text">Erhöhen Sie die Sichtbarkeit Ihrer Internetpräsenz im World-Wide-Web
                                und erhalten mehr Aufrufe.
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>SEO Optimierung</h3>
                            <p class="grey-text">Ich analysiere Ihre bestehende Webseite und helfen Ihnen, bei
                                Suchmaschinen besser gefunden
                                zu werden.
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->

                    <!--Third row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>SEO Strategie</h3>
                            <p class="grey-text">Gerne helfe ich Ihnen auch bei der Planung Ihrer neuen Internetseite
                                um von Anfang an eine höchstmögliche
                                Sichtbarkeit zu erzielen.
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Third row-->

                </div>
                <!--/First column-->

                <!--Second column-->
                <div class="col-md-4 flex-center">
                    <a routerLink="/seo" title="JIRA Software">
                        <img src="assets/screens.png" alt="Google SEO Suchmaschinenoptimierung"
                            title="Google SEO Suchmaschinenoptimierung" class="img-fluid">
                    </a>
                </div>
                <!--/Second column-->

                <!--Third column-->
                <div class="col-md-4 mt-2">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>OnPage</h3>
                            <p class="grey-text">Umsetzung von notwendigen technischen und inhaltlichen Maßnahmen zur
                                Steigerung Ihres Rankings.
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>OffPage</h3>
                            <p class="grey-text">Verbesserung der externen Faktoren (Umsetzen der Strategie zum
                                Linkaufbau, interne und externe
                                Links, erzielen von Backlinks).</p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->

                    <!--Third row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>Messbarer Erfolg</h3>
                            <p class="grey-text">Erfolg ist sichtbar! Messen Sie ihn an Hand von ausgewählten
                                Kennzahlen. SEO lohnt sich immer.
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Third row-->

                </div>
                <!--/Third column-->

            </div>
            <!--/First row-->

        </section>
        <!-- /SEO Section -->
        <hr class="my-5">

        <!--Software Entwicklung Section-->
        <section>

            <h2 class="h2 text-center mb-5">Software-Entwicklung</h2>

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-lg-6 col-md-12 px-4">

                    <!--First row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fab fa-android fa-2x mb-1 green-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>Android</h3>
                            <p class="grey-text">Ich programmiere Ihre attraktive Android-App und kümmere mich um
                                die Bereitstellung im Google
                                Play Appstore.</p>
                        </div>
                    </div>
                    <!--/First row-->

                    <div class="seperate"></div>

                    <!--Second row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fab fa-linux fa-2x mb-1 indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>Linux</h3>
                            <p class="grey-text">Sie benötigen eine Anwendung für das Linux-Betriebssystem oder Skripte
                                zur Automatisierungen? Gerne unterstütze ich Sie dabei.
                            </p>
                        </div>
                    </div>
                    <!--/Second row-->

                    <div class="seperate"></div>

                    <!--Third row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fab fa-windows fa-2x mb-1 indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h3>Windows</h3>
                            <p class="grey-text">Ich entwickel für Sie Windows-Anwendungen mit Fokus auf einfacher
                                Bedienung und Wartbarkeit.</p>
                        </div>
                    </div>
                    <!--/Third row-->

                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-lg-6 col-md-12">

                    <img src="assets/software-landingpage.jpg" class="img-fluid shadow" alt="Software-Entwicklung "
                        title="Laptop mit Quellcode-Editor zur Software-Entwicklung">

                </div>
                <!--/Grid column-->

            </div>
            <!--/Grid row-->

        </section>
        <!--/Software Entwicklung Section-->

        <hr class="my-5">

        <!--Responsive Design Section-->
        <section class="mt-5">

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <img src="assets/webdesign.jpg" class="img-fluid shadow"
                        alt="Responsive Design mit Laptop und Smartphone "
                        title="Schreibtisch mit Smartphone, Desktop & Laptop">

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <!-- Main heading -->
                    <h2 class="h2 mb-2">Responsive Design</h2>
                    <p>
                        Egal ob Sie Privatperson oder Freelancer sind, Einzelunternehmer oder ein
                        kleines / mittleres Unternehmen führen: Eine moderne Webseite ist Ihr Aushängeschild und
                        repräsentiert ihre Firma.
                    </p>
                    <p>
                        Begeistern Sie Ihre Besucher doch direkt von Beginn an mit
                        einer professionellen Webseite. Lassen Sie uns zusammen ihren Auftritt im Internet planen und
                        damit den Grundstein für den digitalen Erfolg legen.
                    </p>
                    <p>Gemeinsam erstellen wir die Struktur, planen das Design und legen Inhalt fest.
                        Erhalten Sie hier mehr Informationen zu Responsive Webdesign.</p>

                    <a class="btn btn-indigo btn-md waves-effect waves-light shadow ml-0" routerLink="responsive-design"
                        title="Responsive Design">Weiter
                        zu Responsive Design
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>
        <!--/Responsive Design Section-->

        <hr class="my-5">

        <!-- Online-Marketing Section -->
        <section>

            <h2 class="my-5 h2 text-center">Online-Marketing</h2>

            <div class="row mb-4 wow fadeIn">

                <!--Grid column-->
                <div class="col-lg-4 col-md-12 mb-4">

                    <!--Card-->
                    <div class="card wow slideInUp" data-wow-delay="0.2s">

                        <!--Card image-->
                        <div class="view overlay">
                            <img src="assets/affiliate-marketing.jpg" class="card-img-top" alt="Affiliate Marketing App"
                                title="Affiliate Marketing App mit Einnahmen">
                        </div>

                        <!--Card content-->
                        <div class="card-body">
                            <!--Title-->
                            <h3 class="card-title">Affiliate Marketing</h3>
                            <!--Text-->
                            <p class="card-text">Sie möchten zusätzliches Einkommen generieren? Steigen Sie in das
                                Empfehlungsmarketing ein, ich
                                helfe Ihnen dabei!</p>
                        </div>

                    </div>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4">

                    <!--Card-->
                    <div class="card wow slideInUp" data-wow-delay="0.4s">

                        <!--Card image-->
                        <div class="view overlay">
                            <img src="assets/online-werbung.jpg" class="card-img-top"
                                alt="Online-Marketing Präsentation am Tablet"
                                title="Online-Marketing Präsentation am Tablet">

                            <div class="mask rgba-white-slight waves-effect waves-light"></div>
                        </div>

                        <!--Card content-->
                        <div class="card-body">
                            <!--Title-->
                            <h3 class="card-title">Online-Werbung</h3>
                            <!--Text-->
                            <p class="card-text">Sorgen Sie für Aufmerksamkeit! Mit Bannern, Popups oder Layer-Ads auf
                                Plattformen, die für ihre
                                Zielgruppe relevant sind.</p>
                        </div>

                    </div>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4">

                    <!--Card-->
                    <div class="card wow slideInUp" data-wow-delay="0.6s">

                        <!--Card image-->
                        <div class="view overlay">
                            <img src="assets/social-media.jpg" class="card-img-top" alt="Sozialen Medien"
                                title="Puzzleteile zur Veranschaulichung der Kanäle von sozialen Medien">
                        </div>

                        <!--Card content-->
                        <div class="card-body">
                            <!--Title-->
                            <h3 class="card-title">Soziale Medien</h3>
                            <!--Text-->
                            <p class="card-text">Präsentieren Sie ihre Firma oder ihr Produkt in den sozialen Medien und
                                treten
                                mit neuen Kunden in Kontakt.
                            </p>
                        </div>

                    </div>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

            </div>
        </section>
        <!-- /Online-Marketing Section -->

        <hr class="my-5">

        <!-- IT-Projektmanagement Section -->
        <section>

            <h2 class="my-5 h2 text-center">IT-Projektmanagement</h2>

            <!--First row-->
            <div class="row features-small mt-5 wow fadeIn">

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-plane-departure fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2 pl-3">
                            <p class="pm font-bold text-dark mb-1">Initiierung</p>
                            <p class="grey-text mt-2">Von der Idee zum Projekt. Erfassen der Ausgangssituation und des
                                Ziels. Aufwands- und Kostenschätzung.
                                Definieren der Projektorganisation. Erstellen des Projektauftrags.
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-clipboard-check fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Planung</p>
                            <p class="grey-text mt-2">Festlegen des Projektumfangs und identifizieren von
                                Arbeitspaketen. Erstellen von Zeit-, Kosten-
                                und Ressourcenplänen.
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-magic fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Steuerung</p>
                            <p class="grey-text mt-2">Steuerung der Durchführung an Hand vom Projektplan. Starten von
                                Arbeitspaketen, Führung der Projektmitarbeiter,
                                Überwachung der Zielerreichung.
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Abschluss</p>
                            <p class="grey-text mt-2">Abnahme des Projektendprodukts, der Dokumentation und Bewertung
                                des Projekts.
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

            </div>
            <!--/First row-->

            <!--Second row-->
            <div class="row features-small mt-4 wow fadeIn">

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-list-ol fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Methoden</p>
                            <p class="grey-text mt-2">Ich wähle die für Sie beste Vorgehensweise. Dabei kann ich auf
                                alt bewährte sowie moderne,
                                agile Methoden wie zum Beispiel SCRUM zurückgreifen.</p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-toolbox fa-2x mb-1 indigo-text" aria-hidden="true"></i>

                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Werkzeuge</p>
                            <p class="grey-text mt-2">Bereitstellung von Projektmanagement Tools an Hand der
                                Anforderungen.
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-chalkboard-teacher fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">IT-Wissen</p>
                            <p class="grey-text mt-2">Ich habe langjährige Erfahrung im System Engineering, der
                                Entwicklung von Anwendungen und habe bereits
                                viele IT-Projekte erfolgreich umgesetzt.</p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-certificate fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <p class="pm font-bold text-dark mb-1">Zertifizierung</p>
                            <p class="grey-text mt-2">Die IT-Welt dreht sich rasend schnell. Um für Sie immer am Ball zu
                                bleiben, bilde ich mich stetig weiter. Zum Beispiel mit PRINCE2® und SCRUM-Master
                                Zertifizierungen.</p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

            </div>
            <!--/Second row-->

        </section>
        <!--/IT-Projektmanagement Section-->

    </div>
</main>