<div class="view withoutBackgroundPicture">
  <div class="container">
    <section class="content-section">
      <div class="row">
        <div class="col-lg-12">
          <!-- Überschrift -->
          <h1>Geschäftsführung</h1>
        </div>
      </div>

      <div class="row pt-5">
        <!-- Erste Spalte -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
          <div class="about-title">
            <img src="assets/Wenzke-neu.jpg" class="img-fluid" title="Patrick Wenzke" alt="Patrick Wenzke">
            <br />
            <p><em>Patrick Wenzke</em></p>
          </div>
        </div>
        <!--/Erste Spalte-->

        <!-- Zweite Spalte -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
          <div class="about-entry first">
            <h5 class="text-uppercase">Patrick Wenzke</h5>
            <span class="break red-break"></span>

            <p>ich bin Patrick Wenzke, der Gründer und Geschäftsführer von Wenzke-Consulting. Mit umfassendem Fachwissen
              und langjähriger Expertise im System-Engineering, IT-Projektmanagement und der Anwendungsentwicklung stehe
              ich persönlich zur Verfügung, um Ihr Unternehmen optimal zu unterstützen.</p>

            <p>Meine berufliche Reise begann nach meiner herausragenden Ausbildung zum Fachinformatiker bei der
              Deutschen Telekom AG im Jahr 2010, als es mich von Köln nach München verschlug. Über mehrere Jahre hinweg
              war ich als IT-Systemingenieur im Bereich Mobile Device Management bei der T-Systems International GmbH
              tätig. In dieser Rolle verantwortete ich die Entwicklung einer Cloud-Plattform und die Programmierung von
              Funktionen auf Basis eines SAP-Produkts.</p>

            <p>Um meine Expertise im IT-Projektmanagement zu vertiefen, erfolgte im Herbst 2015 mein Wechsel zur Siemens
              BKK. Durch kontinuierliche Weiterbildung konnte ich Zertifizierungen in ITIL 4, PRINCE2, SCRUM und als
              JIRA-Admin erlangen.</p>

            <p>Im Juli 2015 gründete ich stolz Patrick Wenzke Consulting in Oberschleißheim, im Norden von München.
              Unser Unternehmen bietet ein vielseitiges Portfolio an IT-Dienstleistungen. Entscheiden Sie sich bewusst
              gegen anonyme Großunternehmen und setzen Sie auf die persönliche, lösungsorientierte und kompetente
              Betreuung von Patrick Wenzke Consulting.</p>

            <p>Wir zeichnen uns durch klare Kommunikation und unkomplizierte Arbeitsabläufe aus. Bei uns erhalten Sie
              nicht nur ein Höchstmaß an Fachkompetenz, sondern auch einen einzigartigen Service. Ich persönlich stehe
              hinter meinem Unternehmen, und mein vorrangiges Ziel ist es, Ihre Zufriedenheit zu gewährleisten.</p>

            <p>Herzlichst,<br />Ihr Patrick Wenzke</p>
            <img class="img-fluid" src="/assets/Unterschrift.png" alt="Unterschrift Patrick Wenzke"
              title="Unterschrift Patrick Wenzke">
          </div>
        </div>
        <!--/Zweite Spalte-->

        <!-- Dritte Spalte -->
        <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/itil.jpg" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/itil-thumb.jpg" alt="ITIL 4 Zertifikat"
                title="ITIL 4 Zertifikat">
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Prince2.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Prince2-thumb.png" alt="PRINCE2 Zertifikat"
                title="PRINCE2 Zertifikat">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Jira-Administrator.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Jira-Administrator-thumb.jpg"
                alt="Certified Jira Administrator" title="Certified Jira Administrator">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/scrum.jpg" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/scrum-thumb.jpg" alt="Professional SCRUM Master"
                title="Professional SCRUM Master">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/microsoft-ad.jpg" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/microsoft-ad.jpg" alt="Microsoft Active Directory Zertifikat"
                title="Microsoft Active Directory Zertifikat">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Solution-Architect.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Solution-Architect-thumb.png" alt="MobileIron Solution-Architect"
                title="MobileIron Solution-Architect">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Auszeichnung.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Auszeichnung-thumb.png"
                alt="Fachinformatiker Auszeichnung" title="Fachinformatiker Auszeichnung">
            </a>
          </div>

          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Sales-Specialist.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Sales-Specialist-thumb.png" alt="SAP Sybase Sales-Specialist"
                title="SAP Sybase Sales-Specialist">
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-xs-6">
            <a href="/assets/Infrastructure-Specialist.png" target="_blank" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" src="/assets/Infrastructure-Specialist-thumb.png"
                alt="SAP Sybase Infrastructure-Specialist" title="SAP Sybase Infrastructure-Specialist">
            </a>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>