<div class="view withoutBackgroundPicture">

    <div class="container">

        <!-- Calendly Section -->
        <section class="mt-5">

            <h2 class="my-5 h2 text-center">Kostenloses Beratungsgespäch vereinbaren</h2>

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-12 mb-4">

                    <div class="calendly-inline-widget" data-url="https://calendly.com/wenzkeconsulting/15min"
                        style="min-width:320px;height:660px;"></div>

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>
        <!-- /Calendly Section -->
    </div>
</div>