import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.css']
})

@Injectable()
export class AgbComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Allgemeine Geschäftsbedingungen von www.wenzke-consulting.de - Wenzke-Consulting - Prof.-Otto-Hupp-Str.9, 85764 Oberschleißheim' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'AGB, Allgemeine Geschäftsbedingungen' });
    this.title.setTitle("Allgemeine Geschäftsbedingungen - Wenzke-Consulting");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  ngOnInit() {
    // Scroll to top after link click
    window.scroll(0, 0);
  }

}
