import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// routing
import { RouterModule, Routes } from '@angular/router';

// components
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { JiraComponent } from './jira/jira.component';
import { SeoComponent } from './seo/seo.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { SoftwareEntwicklungComponent } from './software-entwicklung/software-entwicklung.component';
import { ResponsiveDesignComponent } from './responsive-design/responsive-design.component';
import { OnlineMarketingComponent } from './online-marketing/online-marketing.component';
import { ItProjektmanagementComponent } from './it-projektmanagement/it-projektmanagement.component';
import { ShopsComponent } from './shops/shops.component';
import { AgbComponent } from './agb/agb.component';
import { ReferenzenComponent } from './referenzen/referenzen.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { BeratungsgespraechComponent } from './beratungsgespraech/beratungsgespraech.component';
import { ManagementComponent } from './management/management.component';
import { ModalComponent } from './modal/modal.component';
import { NotFoundComponent } from './not-found/not-found.component';

// routing paths
const appRoutes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'app-mainpage',
    component: LandingComponent
  },
  {
    path: 'jira',
    component: JiraComponent
  },
  {
    path: 'seo',
    component: SeoComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent
  },
  {
    path: 'software-entwicklung',
    component: SoftwareEntwicklungComponent
  },
  {
    path: 'responsive-design',
    component: ResponsiveDesignComponent
  },
  {
    path: 'online-marketing',
    component: OnlineMarketingComponent
  },
  {
    path: 'it-projektmanagement',
    component: ItProjektmanagementComponent
  },
  {
    path: 'shops',
    component: ShopsComponent
  },
  {
    path: 'agb',
    component: AgbComponent
  },
  {
    path: 'referenzen',
    component: ReferenzenComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: 'beratungsgespraech',
    component: BeratungsgespraechComponent
  },
  {
    path: 'management',
    component: ManagementComponent
  },
  {
    path: 'not-found', component: NotFoundComponent
  },
  {
    path: '**', redirectTo: '/not-found'
  }

]

@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        LandingComponent,
        JiraComponent,
        SeoComponent,
        ImpressumComponent,
        DatenschutzComponent,
        SoftwareEntwicklungComponent,
        ResponsiveDesignComponent,
        OnlineMarketingComponent,
        ItProjektmanagementComponent,
        ShopsComponent,
        AgbComponent,
        ReferenzenComponent,
        KontaktComponent,
        BeratungsgespraechComponent,
        ManagementComponent,
        ModalComponent,
        NotFoundComponent
    ],
    exports: [RouterModule],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(appRoutes, {})
        //RouterModule.forRoot(appRoutes, { useHash: true })
    ], providers: [
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
