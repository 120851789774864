import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})
export class ShopsComponent implements OnInit {


  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Entwicklung und Betrieb von Online-Shops ✓  bessere Sichtbarkeit' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'E-Commerce, Online Shops, Onlineshop erstellen lassen, WooCommerce' });

    // Open Graph Meta information
    this.meta.addTag({ property: 'og:locale', content: 'de_DE' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: 'Onlineshop erstellen lassen von Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:description', content: 'Online-Shop von Wenzke-Consulting erstellen lassen & weltweit verkaufen.' });
    this.meta.addTag({ property: 'og:site_name', content: 'Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.wenzke-consulting.de/shops' });
    this.meta.addTag({ property: 'og:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });

    // Twitter Meta information
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@wenzkeconsult' });
    this.meta.addTag({ name: 'twitter:title', content: 'Onlineshop erstellen lassen von Wenzke-Consulting' });
    this.meta.addTag({ name: 'twitter:description', content: 'Online-Shop von Wenzke-Consulting erstellen lassen & weltweit verkaufen.' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });
    
    // Website title
    this.title.setTitle("Onlineshop erstellen lassen von Wenzke-Consulting");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  ngOnInit(): void {
    // Scroll to top after link click
    window.scroll(0, 0);
  }

  scroll(el) {
    el.scrollIntoView();
  }

}
