<!--Online-Marketing Intro-->
<div id="online-marketing-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Online-Marketing</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h4 class="white-text my-4">Steigern Sie die Bekanntheit Ihrer Marke</h4>

          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.Online-Marketing Intro-->

<main>

  <div class="entry-wrap pt-5 pb-5">

    <div class="container">

      <section #target>

        <!--First row-->
        <div class="row wow fadeInRight">

          <!--First column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">

            <div class="about-title">

              <i class="fas fa-people-carry fa-4x blue-text"></i>

            </div>

          </div>
          <!--/First column-->

          <!--Second column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-8">

            <div class="about-entry first">

              <h2>Was ist Online-Marketing?</h2>
              <span class="break red-break"></span>

              <p>
                Die Werbung hat sich im Zuge der Digitalisierung durch <strong>Online-Marketing</strong> deutlich
                verändert. Wo früher noch Zeitungsannoncen,
                gedruckte Kleinanzeigen
                und Flyer in Briefkästen das Mittel der Wahl waren, haben sich nun Online-Marketing-Instrumente
                durchgesetzt.
              </p>
              <p>
                In der digitalen Welt gibt es viele verschiedene Werbemaßnahmen mit unterschiedlichem Wirkungsgrad. Es
                haben sich Begriffe
                wie Content-Marketing, E-Mail-Marketing und Affiliate-Marketing eingebürgert. Für die Kundenakquise und
                Kundenbindung
                führt heutzutage kein Weg am Online-Marketing vorbei.
              </p>

              <p>
                Hierbei ist es wichtig, dass vor der Umsetzung von Maßnahmen eine Marketing-Strategie entwickelt wird,
                mit der die definierten
                Ziele erreicht werden können. Nach der Umsetzung werden Auswertungen durchgeführt, um Erfolge zu messen
                und
                Rückschlüsse auf die Wirkungskraft der jeweiligen Maßnahme zu erhalten.
              </p>

            </div>

          </div>
          <!--/Second column-->

        </div>
        <!--/First row-->
      </section>

    </div>

  </div>

  <div class="container">

    <section class="pt-5">

      <!--Second row-->
      <div class="row mb-5 wow fadeInLeft">

        <!--First column-->
        <div class="col-lg-7">

          <div class="about-entry">
            <h3>Online-Werbung</h3>
            <span class="break red-break"></span>
            <p>
              Schalten Sie Online-Werbung und ziehen sie neue Besucher an. Ich wähle mit hoher Sorgfalt den richtigen
              Kanal für Ihre
              Werbung aus.
              Gemeinsam mit Ihnen gestalten wir beispielsweise rechtssichere Newsletter und werben potenzielle
              Neukunden. Durch interaktive Online-Werbung lenken Sie das Augenmerk des Kunden auf Sie.
            </p>
            <p>
              Mit den verschiedenen Instrumenten der Internetwerbung können wir zusammen genau Ihre Zielgruppe
              ansprechen, um
              den höchstmöglichen Erfolg zu erreichen. Der Erfolg der Marketingkampagne lässt sich an Hand
              unterschiedlicher Faktoren messen.
              Somit können sie schnell entscheiden, ob sich eine weitere Investition in den jeweiligen Kanal lohnt.
            </p>
          </div>

        </div>
        <!--./First column-->

        <!--Second Column-->
        <div class="col-lg-5">

          <img src="assets/internet-marketing.png" class="img-fluid" alt="Online-Marketing" title="Online-Marketing">

        </div>
        <!--./Second Column-->

      </div>
      <!--./Second row-->

    </section>

  </div>

  <div class="entry-wrap">

    <div class="container">

      <section class="pt-5 pb-5 wow fadeInRight">

        <!--Third row-->
        <div class="row">

          <!--First column-->
          <div class="col-lg-4">
            <img src="assets/online-marketing-1.jpg" class="img-fluid shadow" alt="Affiliate-Marketing"
              title="Affiliate-Marketing">
          </div>
          <!--./First column-->

          <!--Second column-->
          <div class="col-lg-8">
            <div class="about-entry">
              <h3>Affiliate-Marketing</h3>
              <span class="break red-break"></span>
              <p>
                <strong>Affiliate-Marketing</strong> verhilft dem Onlinehandel, jährlich über 7 Milliarden Euro
                umzusetzen!
                Profitieren auch Sie von Affiliate-Marketing und erhalten passives Einkommen. Generieren Sie als Publisher
                Umsätze für Verkäufer von Produkten oder Leistungen und erhalten dafür eine ansehnliche
                Provision.
                Ich helfen Ihnen dabei, einen schönen und dauerhaften Nebenverdient zu erhalten.
              </p>
              <p>
                Sie kennen sich in verschiedenen Themenbereichen besser aus als andere? Dann können Sie mit Ihrer
                Expertise und einer
                ansprechenden Webseite Kunden von Produkten überzeugen und dabei Geld verdienen. Ich helfe Ihnen dabei,
                die
                passenden Advertiser zu
                finden und Ihr Vorhaben umzusetzen.
                Warten Sie nicht lange und helfen Sie anderen dabei, das richtige
                Produkt zu finden und erhalten dafür eine Beteiligung am Gewinn des Anbieters.
              </p>
            </div>

          </div>
          <!--./Second column-->

        </div>
        <!--./Third row-->

      </section>

    </div>

  </div>

  <div class="container">

    <section class="pt-5 pb-5 wow fadeInLeft">

      <div class="entry-block">

        <!--Fourth row-->
        <div class="row">

          <!--First column-->
          <div class="col-lg-8">

            <div class="about-entry first">
              <h3>Soziale Medien</h3>
              <span class="break red-break"></span>
              <p>Das Social Media Marketing befasst sich mit dem Bewerben von Produkten auf sozialen Netzwerken.
                Es dient dazu, die Bekanntheit zu steigern, dass Image zu verbesser oder neue Kunden zu binden.
                Soziale Netzwerke werden heute stärker denn je genutzt und sind ein idealer Kanal, um viele Menschen zu
                erreichen.</p>
              <p>Beispiele für soziale Netzwerke mit vielen Mitgliedern sind:</p>
              <ul>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Pinterest</li>
                <li>Twitter</li>
              </ul>
              <p>Mit Bildern und Videos in Werbeanzeigen (Ads) erreichen Sie schnell die Aufmerksamkeit von Menschen.
                Nun macht es wenig Sinn, allen Mitgliedern Ihre Werbeanzeige zu präsentieren.
                Der junge und trainierte Sportler wird sich nur in den seltensten Fällen für eine Anti Aging Creme
                interessieren.
                Sie benötigen von Beginn an eine Strategie, um möglichst effektiv zu werben.
                Gemeinsam mit Ihnen legen wir an Hand von Altersklassen, Demografie und Interessen zuvor die
                anzusprechende Zielgruppe fest.
              </p>
              <p>Ich helfe Ihnen dabei, diese Menschen richtig anzusprechen.</p>
            </div>

          </div>
          <!--./First column-->

          <!--Second column-->
          <div class="col-lg-4">

            <img src="assets/soziale-medien.jpg" class="img-fluid shadow" alt="Soziale Netwerke"
              title="Soziale Netwerke">

          </div>
          <!--./Second column-->

        </div>
        <!--./Fourth row-->

        <!-- Call to action -->
        <div class="row">
          <div class="col-md-5 p-lg-3 mx-auto my-3">

            <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
              href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/23"
              target="_blank" title="Marketing Anfrage">Ich will online erfolgreich sein
              <i class="fas fa-arrow-right ml-1"></i>
            </a>

          </div>
        </div>

      </div>

    </section>

  </div>

</main>