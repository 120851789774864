<!--Mask-->
<div id="jira-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Jira Software</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h4 class="white-text my-4">Projekt- und Aufgabenmanagement leicht gemacht</h4>
          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.Mask-->

<main>

  <div class="container">

    <section class="mt-5" #target>

      <!--First Row-->
      <div class="row wow fadeIn">

        <!--First Column-->
        <div class="col-lg-8">

          <h2>Was ist Jira?</h2>
          <ul class="list-unstyled">
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i>
              <strong>Jira Software</strong> - Die Nr. 1 unter den Projektmanagement-Tools für agile Teams
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Marktführende Webanwendung für Projekt- und
              Aufgabenmanagement
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Wird in mehr als 50.000 Unternehmen eingesetzt,
              jeder Branche und Größe
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Viele Erweiterungen verfügbar (z.B Jira Servicemanagement,
              Portfolio uvm.)
            </li>
          </ul>
        </div>
        <!--./First Column-->

        <!--Second Column-->
        <div class="col-lg-4">
          <img src="assets/jira-checkliste.jpg" class="img-fluid shadow" alt="JIRA Software ToDo Liste"
            title="JIRA ToDo Liste" />
        </div>
        <!--./Second Column-->

        <div class="col-lg-12">
          <p><a href="https://www.atlassian.com/de/software/jira" target="_blank" title="Atlassian JIRA">Atlassian</a>
            bietet mit seiner Produktpalette viele tolle Werkzeuge an, die Ihnen das Leben leichter machen
            werden.</p>
          <p>Sie nutzen für Ihre Aufgaben oder ihr Projekt immer noch Excel-Sheets, die in unterschiedlichen
            Versionsständen
            per E-Mail durch das Firmennetz schwirren? Alles andere als innovativ. Schluss damit!</p>
          <p>Mit der Einführung von Jira Software erhalten Sie eine komfortable und innovative Oberfläche zur
            Verwaltung Ihrer
            Aufgaben und
            Projekte. Arbeiten Sie gemeinsam an einem zentralen Ort und profitieren von Kollaboration in Zeiten der
            Digitalisierung.
          </p>
          <p>
            Nach der Jira Einführung können Sie den Aufwand der Arbeitspakete schätzen und priorisieren die Reihenfolge
            der
            durchzuführenden
            Arbeiten. Hinterlegen
            Sie klare Verantwortlichkeiten (wer bearbeitet was und bis wann) und werden über Neuigkeiten automatisch
            informiert.
            So behalten Sie das Ziel stets im Blick und bleiben fokussiert. Auch ich vertraue auf Jira Software und
            nutze es für
            unser <a routerLink="/it-projektmanagement" title="IT-Projektmanagement">IT-Projektmanagement</a>
            und unsere <a routerLink="/software-entwicklung"
              title="Software-Entwicklung">Software-Entwicklungsaufgaben</a>.
          </p>
          <p>
            Starten Sie jetzt mit der Jira Einführung und arbeiten effektiver und kostengünstiger als je zuvor.
          </p>

        </div>

        <div class="col-lg-12">
          <img src="assets/jira-kanban-board.png" class="img-fluid shadow" alt="JIRA KanBan Board"
            title="JIRA KanBan Board" />
        </div>

      </div>
      <!--./First Row-->

    </section>

    <!-- Divider -->
    <hr class="my-5">

  </div>

  <div class="container">

    <section class="mt-5">

      <h2 class="my-5 h2 text-center">Was kann Jira?</h2>

      <!--First row-->
      <div class="row features-small mb-5 mt-3">

        <!--First column-->
        <div class="col-md-4 wow fadeInLeft">

          <!--First row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Aufgaben Management</h3>
              <p class="grey-text">Nutzen Sie Jira als
                <strong>Aufgaben Manager</strong> und erhalten echte Transparenz: Wer erledigt was und bis wann.
              </p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/First row-->

          <!--Second row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Software Prozesse abbilden</h3>
              <p class="grey-text">Das Anforderungsmanagement, die Statusverfolgung und der Fehlerbehebungsprozess (
                <strong>Bug Tracker</strong>) können hier anschaulich und effizient durchgeführt werden.
              </p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/Second row-->

          <!--Third row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Boards</h3>
              <p class="grey-text">Visualisierung für die agilen SCRUM und KanBan-Methoden.
              </p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/Third row-->

        </div>
        <!--/First column-->

        <!--Second column-->
        <div class="col-md-4 flex-center">
          <img src="assets/jira-software.png" alt="Jira Software" title="JIRA Software Logo" class="img-fluid">
        </div>
        <!--/Second column-->

        <!--Third column-->
        <div class="col-md-4 mt-2  wow fadeInRight">
          <!--First row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Workflows</h3>
              <p class="grey-text">Definieren von einfachen und nachvollziehbaren Arbeitsabläufen, die ihre fachlichen
                und technischen Anforderungen
                erfüllen.
              </p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/First row-->

          <!--Second row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Reports</h3>
              <p class="grey-text">Report und Controlling Funktionen zum frühzeitigen Erkennen von Engpässen.</p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/Second row-->

          <!--Third row-->
          <div class="row">

            <div class="col-2">
              <i class="fas fa-check-circle fa-2x indigo-text"></i>
            </div>

            <div class="col-10">
              <h3>Jira Mobile</h3>
              <p class="grey-text">Apps für das Arbeiten von unterwegs. Verfügbar für Android und iOS.
              </p>
              <div class="spacer"></div>
            </div>

          </div>
          <!--/Third row-->

        </div>
        <!--/Third column-->

      </div>
      <!--/First row-->

    </section>

  </div>

  <div class="dark">

    <div class="container">

      <section class="mt-5">

        <div class="row wow fadeIn">

          <div class="col-lg-12">
            <h2>Warum brauchen Sie Jira?</h2>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Vermeidet unproduktives Multitasking - häufiger Wechsel zwischen Aufgaben reduzieren.
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Effektives Zusammenarbeiten von Teams und Mitarbeitern, auch über Standorte hinweg.
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Dient als Schnittstelle zwischen IT und Business.
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Jederzeit eine transparente Überwachung des Fortschritts.
            </p>
          </div>

        </div>

      </section>

    </div>

  </div>

  <div class="container">

    <section class="mt-5 wow fadeInLeft">

      <div class="row">

        <div class="col-lg-12">

          <h2>Leistungen im Überblick</h2>

          <ul class="list-unstyled">
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text  mr-2 mt-2"></i> Unterstützung bei der Atlassian
              Jira Einführung
            </li>
            <li>
            <i class="fas fa-angle-double-right fa-2x indigo-text  mr-2 mt-2"></i> Lizenzbeschaffung
            </li>
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text  mr-2 mt-2"></i> Installation von Jira Software &
              Updates
            </li>

            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text  mr-2 mt-2"></i> Einrichtung Ihrer Jira-Projekte (Anlage
              von Benutzern, Projekten, Arbeitsabläufen, Berechtigungen und Benachrichtigungen.)
            </li>

            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text  mr-2 mt-2"></i> Erstellen von Backups
            </li>
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i> Erstellen und einrichten von Jira
              Projekten
            </li>
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i> Betreuung und Hilfe bei der
              täglichen Anwendung
            </li>
          </ul>

        </div>

      </div>

    </section>

    <!-- Divider -->
    <hr class="my-5">

  </div>

  <div class="container">

    <section class="mt-5 mb-5">

      <div class="row wow fadeIn">

        <div class="col-lg-12">

          <!-- Call to action -->
          <div class="row">
            <div class="col-md-5 p-lg-3 mx-auto my-3">

              <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
                href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/21"
                target="_blank" title="JIRA Anfrage">Ich will
                JIRA nutzen
                <i class="fas fa-arrow-right ml-1"></i>
              </a>

            </div>
          </div>

        </div>

      </div>

    </section>

  </div>

</main>