<!--Responsive Design Intro-->
<div id="webdesign-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Responsive Design</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h3 class="white-text my-4">Erfolgreich im World Wide Web</h3>
          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.Responsive Design Intro-->

<main>

  <div class="entry-wrap pt-5 pb-5">

    <div class="container">

      <section #target>

        <!--First Row-->
        <div class="row wow fadeIn">

          <!--First Column-->
          <div class="col-md-6">
            <h2>Webseiten nach Maß
            </h2>
            <p>
              Begeistern Sie ihre Kunden mit einem professionellen Auftritt im Internet. Mit einem responsive Design
              erhalten Sie die perfekte Website auf allen Geräten. Gemeinsam mit Ihnen planen und
              realisieren wir
              Ihre Präsenz im World Wide Web.
            </p>
            <ul class="icon-list">
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                <strong>Konzepte die funktionieren</strong> - Wir planen gemeinsam die Struktur, das Design und legen
                Inhalt
                fest.
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                <strong>Responsive Design</strong> - Ihre Webseite wird optimiert für die Ansicht auf PC, Laptop,
                Smartphone
                & Tablet.
              </li>
              <li>
                <i class="fa fa-check green-text mt-2"></i>
                <strong>Moderne Technologien</strong> - Die Entwicklung erfolgt mittels neuester Technologien.
              </li>
            </ul>
          </div>
          <!--./First Column-->

          <!--Second Column-->
          <div class="col-md-5 col-md-offset-1">
            <img src="assets/responsive-design.jpg" alt="Responsive Design" title="Responsive Design"
              class="img-fluid shadow">
          </div>
          <!--./Second Column-->

        </div>
        <!--./First Row-->

        <!--Second Row-->
        <div class="row wow fadeIn mt-5">

          <!--First Column-->
          <div class="col-lg-6">
            <img src="assets/webdesign-invest.jpg" alt="Warum es sich lohnt, in eine neue Webseite zu investieren"
              title="Notizen mit Papier und Stift" class="img-fluid shadow">
          </div>
          <!--./First Column-->

          <!--Second Column-->
          <div class="col-lg-6">

            <h2>Was ist responsive Design?</h2>
            <p>
              Unter Responsive Design versteht man die für das jeweilige Gerät optimierte Darstellungsform einer
              Webseite.
              Die Nutzung von tragbaren Geräten hat in den letzten Jahren stark zugenommen. Smartphones und Tablets mit
              unterschiedlichen Displaygrößen stellen den Inhalt von Webseiten im Browser dar.
            </p>

            <p>
              Nun macht es wenig Sinn, für jeden Gerätetyp eine eigene Webseite zu erstellen, deren Elemente optimal
              ausgerichtet sind.
              Allein die Aktualisierung und Pflege der Seiteninhalte wäre unheimlich aufwendig.
              Eine Lösung für das Problem bietet responsives Design. Hierbei werden das Design und der Inhalt flexibel
              auf die vorhandene Größe angepasst.
            </p>

          </div>
          <!--./Second Column-->

        </div>
        <!--./Second Row-->

      </section>

    </div>

  </div>

  <div class="container">

    <section class="mt-5 mb-5">

      <!--First Row-->
      <div class="row">

        <div class="col-lg-6 wow fadeIn">
          <h2>Warum es sich lohnt, in eine responsive Webseite zu investieren</h2>

          <p>
            In der heutigen Zeit ist eine Internetpräsenz einer der wichtigsten Bestanddteile für die
            Kundengewinnung. Nur eine optimierte
            Webseite wird auf Smartphones, Tablets, Laptops und PCs ansehnlich dargestellt. Fallen Sie positiv auf,
            indem
            Sie Benutzern eine ansprechende, moderne und seriöse Webseite anbieten. Optimiert dargestellt, egal
            welches
            Gerät er gerade benutzt.
          </p>

          <p>
            Meine Webseiten sind grundsätzlich von Anfang an gut geplant. Ich kümmere mich um die Konzeption und
            Struktur unter
            Berücksichtigung ihrer
            Anforderungen. Freuen Sie sich auf ihre benutzerfreundliche Webseite auf neuestem Stand der Technik, mit
            zeitgemäßen
            Design, die Sie jederzeit Freunden, Bekannten, Kunden und Geschäftspartnern stolz präsentieren können.
            Sie
            zufrieden zu stellen ist mein oberstes Ziel.
          </p>
        </div>
        <div class="col-lg-6">
          <img src="assets/responsive-devices.png" class="img-fluid" alt="Struktur auf PC, Smartphone & Tablet"
            title="Struktur auf PC, Smartphone & Tablet">
        </div>

        <div class="col-lg-12">
          <p>
            Die Suchmaschine Google hat kürzlich den "Mobile First"-Ansatz zur Indexierung von Internetseiten
            gestartet.
            Ab sofort wird nicht mehr die Desktopversion, sondern die mobile Version einer Webseite analyisiert.
            Sollte die Darstellung ihrer Webseite nicht für mobile Geräte optimiert sein, hat dies direkte Auswirkung
            auf das Ranking.
          </p>
        </div>

      </div>

      <div class="row mt-5">

        <div class="col-lg-2">
          <img src="assets/ebert.png" class="img-fluid" alt="Tourismusverein-Altusried"
            title="Tourismusverein-Altusried">
          <p class="small"><i>Siegfried Ebert, Tourismusverein-Altusried</i></p>
        </div>

        <div class="col-lg-10">
          <h2>Responsive Design im Einsatz</h2>
          <p>
            <i>"Dank der Lösung von Patrick Wenzke Consulting haben wir endlich eine
              moderne Internetapplikation. Sie bietet eine Übersicht über Ferienwohnungen im Allgäu mit einem
              Belegungsplan, der durch den Vermieter selbst administrierbar ist.
              Und das alles in einer einheitlichen Oberfläche auf allen Geräten. Klasse!"</i>
          </p>
        </div>

      </div>

      <!--./First Row-->

      <!-- Call to action -->
      <div class="row">
        <div class="col-md-5 p-lg-3 mx-auto my-3">

            <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
                href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/18"
                target="_blank" title="Webseite Anfrage">Ich will
                eine Webseite
                <i class="fas fa-arrow-right ml-1"></i>
            </a>

        </div>
    </div>

    </section>

  </div>
</main>