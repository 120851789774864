<!--IT-Projektmanagement Intro-->
<div id="pm-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="font-weight-bold white-text pt-5 mb-2">IT-Projektmanagement</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h3 class="white-text my-4">Setzen sie Ihr Projekt um - in Time und Budget</h3>

          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.IT-Projektmanagement Intro-->

<main>

  <div class="container">

    <section class="mt-5" #target>

      <!--First Row-->
      <div class="row wow fadeIn">

        <!--First Column-->
        <div class="col-lg-8">

          <h2>IT-Projektmanagement von Wenzke-Consulting</h2>

          <p>Sie benötigen
            <strong>Hilfe beim Projekt?</strong>
          </p>

          <p>Mit ganzheitlichem <strong>IT-Projektmanagement</strong> unterstütze ich Sie bei der Planung und
            Durchführung ihres
            informationstechnischen Projekts, bis hin zum
            Abschluss.
            Dabei kann ich auf alt bewehrte, sowie moderne, agile Methoden zurückgreifen.</p>

          <p>Da ich mit <a routerLink="/jira" title="JIRA Software">JIRA
              Software</a> arbeite, haben Sie jederzeit einen Überblick über den aktuellen Status Ihres Projekts.</p>

          <p>Als
            <strong>externe Projektunterstützung</strong> nehme ich ihren Business-Case objektiv unter die Lupe und
            prüfe, ob sich
            die Umsetzung des Vorhabens lohnt.
          </p>

          <p>Mit dem Kick-Off Meetings beginnt der offizielle Start des Projekts. Die Projektbeteiligten lernen sich
            hier kennen
            und bekommen das Ziel und den Nutzen erläutert.</p>

          <p>Zudem erfolgt die Klärung von Aufgaben, Rollen und Verantwortlickeiten, sowie etwaige Kommunikationsregeln
          </p>

          <p>Als Schnittstelle zwischen IT und Anwendern koordiniere ich die beteiligten Mitarbeiter, Abteilungen und
            externen Dienstleister.
            Während der Umsetzung behalte ich den Zeitplan und das Budget immer im Blick.
          </p>

          <p>Als externe Projektunterstützung biete ich Ihnen:</p>
          <ul class="list-unstyled">
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Umfassendes IT-Fachwissen
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Bedarfsermittlung
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Planung und Erstellen der Fachkonzepte
            </li>
            <li>
              <i class="fas fa-award fa-2x indigo-text mr-2 mt-2"></i> Dokumentation
            </li>
          </ul>
        </div>
        <!--./First Column-->

        <!--Second Column-->
        <div class="col-lg-4">
          <img src="assets/jira-checkliste.jpg" class="img-fluid shadow" alt="IT-Projektmanagement"
            title="IT-Projektmanagement Checkliste" />
        </div>
        <!--./Second Column-->

      </div>
      <!--./First Row-->

    </section>

  </div>

  <div class="dark">

    <div class="container">

      <section class="mt-5">

        <!--First Row-->
        <div class="row wow fadeIn">

          <!--First Column-->
          <div class="col-lg-8">
            <h2>Warum Wenzke-Consulting?</h2>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Umfassendes und breit gefächertes IT-Wissen
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Langjährige Erfahrung
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Hohe Einsatzbereitschaft und Verantwortungsbewusstsein
            </p>
            <p class="text-white">
              <i class="fas fa-check-circle mr-2"></i>
              Jederzeit eine transparente Überwachung des Fortschritts
            </p>
          </div>
          <!--./First Column-->

          <!--Second Column-->
          <div class="col-lg-4">

            <p class="white-text text-uppercase fa-2x lh-1">Hör auf, Dinge anzufangen</p>
            <p class="white-text text-uppercase fa-2x lh-1">Fang an, Dinge fertig zu stellen!</p>

          </div>
          <!--./Second Column-->

        </div>
        <!--./First Row-->

      </section>

    </div>

  </div>

  <div class="container">

    <section class="mt-5">

      <!--First Row-->
      <div class="row wow fadeIn">

        <!--First Column-->
        <div class="col-lg-12">

          <h2>Projektmanagement Methoden</h2>
          <p>
            Die Steuerung Ihres Projekts kann entweder klassisch, nach dem altbewährten Wasserfallmodell erfolgen, oder
            Sie
            entscheiden sich für eine agile Vorgehensweise. Beide Methoden haben Ihre Vor- und Nachteile. Je nach
            Projektart (zum Beispiel Umsetzung eines Software- oder Infrastrukturprojekts) kann ich Ihnen einen
            Vorschlag zur optimalen Vorgehensweise
            machen. Die bekanntesten agilen Modelle sind SCRUM und KANBAN.
          </p>

          <h3>Die Vorteile von agilen Methoden</h3>
          <p>
            Aktuellen Umfragen nach, werden durch die Anwendung von agilen Methoden qualitativ bessere Ergebnisse
            im
            Projekt erzielt.
            Außerdem würden Projekte schneller zu Ende gebracht werden, als bei der klassischen Wasserfall-Methode.
            Probleme, die während der Umsetzung auftreten und das Projektziel gefähren, werden zudem schneller
            ausfindig
            gemacht und können schneller beseitigt werden.
            Da die Mitarbeiter mehr Eigenverantwortung im Projekt erhalten, steigt deren Motivation und ihre
            Leistungsbereitschaft.
          </p>

          <h3>Die Vorteile des Wasserfallmodells</h3>
          <p>
            Beim Wasserfallmodell herrscht ein starrer Ablauf, der eine hohe Sicherheit in der Planung mit sich bringt.
            Das Projekt wird in verschiedene Phasen unterteilt, die hierarchisch aufeinander aufbauen.
            Grobe Fehler in der Planung werden somit vermieden. Die Kostenschätzung erfolgt meist präziser, als beim
            agilen
            Vorgehen.
            Anstehende Arbeitsschritte werden der Reihenfolge nach abgearbeitet. Eine präzise Planung sorgt für
            Ordnung, lässt
            jedoch keinen Platz für flexible Anpassungen.
          </p>

          <p>
            Egal für welche Methode Sie sich entscheiden, ich begleite Sie auf dem Weg von der Idee bis zum Abschluss
            des Projekts.
            Ich bin PRINCE2® und als Professional SCRUM MASTER zertifiziert und habe mehr als 10 Jahre Erfahrung im
            IT-Bereich.
            Sprechen Sie mich einfach an!
          </p>

        </div>
        <!--./First Column-->

      </div>
      <!--./First Row-->

    </section>

    <!-- Divider -->
    <hr class="my-5">

  </div>

  <div class="container">

    <section class="mt-5">

      <h2>Leistungen im Überblick</h2>

      <div class="row">

        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.2s">

          <ul class="list-unstyled">
            <li>
              <i class="indigo-text mr-2 mt-2"></i>
              <h4>Definition</h4>
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Businesscase
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Kick-Off
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Kommunikationsmatrix
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Lastenheft
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Pflichtenheft
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Projektauftrag
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Scope
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Stakeholderanalyse
            </li>
            <li class="pl-3">
              <i class="fas fa-ang le-double-right fa-2x indigo-text mr-2 mt-2"></i>Umfeldanalyse
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Vertrag
            </li>
          </ul>

        </div>

        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.4s">

          <ul class="list-unstyled">
            <li>
              <i class="indigo-text mr-2 mt-2"></i>
              <h4>Projektorganisation</h4>
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Rollen und Verantwortung
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Vereinbarungen
            </li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <i class="indigo-text mr-2 mt-2"></i>
              <h4>Projektplanung</h4>
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Arbeitspakete
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Meilensteine
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Finanzen
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Personalplan
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Projektstrukturplan
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Zeitplan
            </li>
          </ul>

        </div>

        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.6s">

          <ul class="list-unstyled">
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Projektrisiken
            </li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <i class="indigo-text mr-2 mt-2"></i>
              <h4>Projektdurchführung</h4>
            </li>

            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Controlling
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Kostenkontrolle
            </li>
            <li class="pl-3">
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Terminkontrolle
            </li>

            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Qualitätsmanagement
            </li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <i class="indigo-text mr-2 mt-2"></i>
              <h4>Abschluss</h4>
            </li>
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Abnahme
            </li>
            <li>
              <i class="fas fa-angle-double-right fa-2x indigo-text mr-2 mt-2"></i>Dokumentation
            </li>
          </ul>

        </div>

      </div>

      <!-- Call to action -->
      <div class="row">
        <div class="col-md-5 p-lg-3 mx-auto my-3">

          <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
            href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/25"
            target="_blank" title="PM Anfrage">Unterstützung im Projektmanagement
            <i class="fas fa-arrow-right ml-1"></i>
          </a>

        </div>
      </div>

    </section>

  </div>

</main>