import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']

})

@Injectable()
export class SeoComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Google SEO - Optimierung von Webseiten für Google ✓ OnPage & OffPage ✓ bessere Sichtbarkeit' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'SEO, Google SEO, Suchmaschinenoptimierung, Platzierung, Ranking' });

    // Open Graph Meta information
    this.meta.addTag({ property: 'og:locale', content: 'de_DE' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: 'Google SEO Suchmaschinenoptimierung - Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:description', content: 'Google SEO - Optimierung von Webseiten für Google ✓ OnPage & OffPage ✓ bessere Sichtbarkeit' });
    this.meta.addTag({ property: 'og:site_name', content: 'Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.wenzke-consulting.de/seo' });
    this.meta.addTag({ property: 'og:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });

    // Twitter Meta information
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@wenzkeconsult' });
    this.meta.addTag({ name: 'twitter:title', content: 'Google SEO Suchmaschinenoptimierung - Wenzke-Consulting' });
    this.meta.addTag({ name: 'twitter:description', content: 'Google SEO - Optimierung von Webseiten für Google ✓ OnPage & OffPage ✓ bessere Sichtbarkeit' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });
    
    // Website title
    this.title.setTitle("Google SEO Suchmaschinenoptimierung - Wenzke-Consulting");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  ngOnInit() {

    // Scroll to top after link click
    window.scroll(0, 0);

  }

  scroll(el) {
    el.scrollIntoView();
  }

}
