<!--SEO Intro-->
<div id="seo-intro" class="view">

  <div class="mask rgba-black-strong">

    <div class="container-fluid d-flex align-items-center justify-content-center h-100">

      <div class="row d-flex justify-content-center text-center">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Google SEO</h1>

          <!-- Divider -->
          <hr class="hr-light">

          <!-- Description -->
          <h4 class="white-text my-4">Suchmaschinenoptimierung - Damit Sie gefunden werden.</h4>

          <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
            <i class="fas fa-graduation-cap"></i>
          </button>

        </div>

      </div>

    </div>

  </div>

</div>
<!--/.SEO Intro-->

<main>

  <div class="entry-wrap pt-5 pb-5">

    <div class="container">

      <section #target>

        <!--First row-->
        <div class="row wow fadeInRight">

          <!--First column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">

            <div class="about-title">

              <i class="fas fa-people-carry fa-4x blue-text"></i>

            </div>

          </div>
          <!--/First column-->

          <!--Second column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-8">

            <div class="about-entry first">

              <h2>Was ist Google SEO?</h2>
              <span class="break red-break"></span>
              <p>
                SEO ist eine Abkürzung und steht für den englischen Begriff "Search Engine Optimization". Die deutsche
                Übersetzung hierfür
                heißt "Suchmaschinenoptimierung". Unter dem Begriff <strong>Google SEO</strong> ist hierbei die
                Optimierung von Webseiten für die Google-Suchmaschine gemeint.
              </p>
              <p>
                Suchmaschinenoptimierung beinhaltet verschiedene technische und inhaltliche Maßnahmen (OnPage &
                OffPage) um bei relevanten
                Suchbegriffen möglichst weit oben zu stehen.
              </p>
              <p>
                Viele Firmen nutzen bereits diese Marketing-Strategie und profitieren von hohen Reichweiten und
                steigern ihren Umsatz durch
                die Akquise von neuen Kunden.
              </p>
            </div>

          </div>
          <!--/Second column-->

        </div>
        <!--/First row-->
      </section>

    </div>

  </div>

  <div class="container">

    <section class="pt-5 pb-5">

      <!--Second row-->
      <div class="row wow fadeInLeft">

        <!--First column-->
        <div class="col-lg-7">

          <div class="about-entry">
            <h2>Warum brauchen Sie SEO?</h2>
            <span class="break red-break"></span>
            <p>
              Stellen Sie sich vor, Sie haben in eine neue, <a routerLink="/responsive-design"
                title="Responsive Design">ansprechend
                aussehende Internetseite</a> investiert.
              Sowohl auf mobilen Endgeräten, als auch auf dem Desktop-PC sieht diese wirklich klasse aus.
              Es gibt nur einen Haken: Sie wird von keinem Besucher im Internet gefunden und aufgerufen!
              Potenzielle Kunden gehen Ihnen hierbei verloren, was faktisch zu einem Umsatzverlust führt.
            </p>
            <p>
              Was wäre, wenn Ihre Webseite bei einer Google Suchanfrage direkt in den ersten TOP 10 Ergebnissen
              erscheint?
              Durch die Umsetzung von SEO OnPage und OffPage Maßnahmen, erhält Ihre Webseite eine deutlich bessere
              Sichtbarkeit.
              Sie erhalten damit automatisch mehr Aufrufe und können Ihre Bekanntheit als auch Ihren Umsatz steigern.
              Eine Investition
              in die Suchmaschinenoptimierung (Google SEO) zahlt sich immer aus, denn sie trägt unmittelbar zum Erfolg
              ihres Unternehmens bei.
            </p>
            <p>
              Heben Sie sich von der Konkurrenz ab und erhalten Sie neue Kunden.
            </p>

          </div>

        </div>
        <!--./First column-->

        <!--Second Column-->
        <div class="col-lg-5">

          <img src="assets/google-seo.jpg" alt="Google SEO bringt Sie nach vorne" title="Google SEO Bild mit Kompass"
            class="img-fluid">

        </div>
        <!--./Second Column-->

      </div>
      <!--./Second row-->

    </section>

  </div>


  <div class="entry-wrap">

    <div class="container">

      <section class="pt-5 pb-5">

        <!--Third row-->
        <div class="row wow fadeInRight">

          <!--First column-->
          <div class="col-lg-4">
            <i class="fab fa-connectdevelop fa-4x blue-text"></i>
          </div>
          <!--./First column-->

          <!--Second column-->
          <div class="col-lg-8">
            <div class="about-entry">
              <h2>Wie funktioniert Google SEO?</h2>
              <span class="break red-break"></span>
              <p>
                Einfach gesagt: Die Suchmaschine Google will ihre Benutzer zufriedenstellen. Durch ausgeklügelte und
                sich stetig
                ändernde Algorithmen
                verfolgt sie das Ziel, dem Suchenden das beste Ergebnis für seine jeweilige Suchanfrage auf den
                obersten Positionen
                anzuzeigen.
              </p>
              <p>
                Hierbei wird vor allem der Inhalt (Content), die verlinkenden Webseiten (Backlinks) und technische
                Parameter in Augenschein
                genommen.
              </p>
              <p>
                Eine leicht verständliche und logisch aufbauende Struktur hilft der Suchmaschine, den Inhalt besser zu
                verstehen. Sie ist
                neben vieler weiterer Stellschrauben ein Teil der OnPage-Optimierung.
              </p>
              <p>
                Die OffPage-Optimierung hat die Aufgabe der Webseite, den notwendigen Einfluss zu verleihen. Trotz des
                Namens dreht sich
                SEO gleichermaßen um Nutzer als um Suchmaschinen. Es geht darum zu verstehen, wie Menschen suchen,
                welche
                Antworten sie benötigen und welche Inhalte ihnen weiterhelfen.
              </p>
              <p>
                Ich analysiere Ihre bestehende Webseite und helfe Ihnen, besser gefunden zu werden.
                Selbstverständlich helfe ich Ihnen auch bei der Planung Ihrer neuen Internetseite, damit Sie von
                Beginn an erfolgreich sind.
              </p>
            </div>

          </div>
          <!--./Second column-->

        </div>
        <!--./Third row-->

      </section>

    </div>

  </div>

  <div class="container">

    <section class="pt-5 pb-5">

      <div class="entry-block">

        <!--Fourth row-->
        <div class="row wow fadeInLeft">

          <!--First column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">

            <div class="about-title">
              <h3>Ihr Vorteil</h3>
              <br />
              <i class="fas fa-chess fa-4x blue-text"></i>
            </div>

          </div>
          <!--./First column-->

          <!--Second column-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-8">

            <div class="about-entry first">
              <h4>Das Ziel</h4>
              <span class="break red-break"></span>
              <p>Der Nutzen von Suchmaschinenoptimierung liegt auf der Hand. Durch einen geringen finanziellen
                Einsatz erhalten Sie einen dauerhaften Vorteil, der Sie von der Konkurrenz abhebt. Lassen Sie sich
                diese Gelegenheit nicht entgehen und vermeiden Sie proaktiv einen Verlust von Neukunden und Umsatz.
                Kontaktieren Sie mich und erhalten ein unverbindliches Angebot.</p>
              <p>Profitieren auch Sie von den Vorteilen von Google SEO um:</p>
              <ul>
                <li>schneller bei Google gefunden zu werden</li>
                <li>neue Kunden zu gewinnen</li>
                <li>ihre Umsätze dauerhaft zu steigern</li>
                <li>mit der Webseite erfolgreich zu sein</li>
                <li>mit der Digitalisierung Schritt zu halten</li>
              </ul>
            </div>

          </div>
          <!--./Second column-->

        </div>
        <!--./Fourth row-->

        <!-- Call to action -->
        <div class="row">
          <div class="col-md-5 p-lg-3 mx-auto my-3">

            <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
              href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/19"
              target="_blank" title="SEO Anfrage">Ich will
              von SEO profitieren
              <i class="fas fa-arrow-right ml-1"></i>
            </a>

          </div>
        </div>

      </div>

    </section>

  </div>

</main>