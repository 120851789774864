<!-- Footer-->
<footer class="footer">

  <!-- Footer Links-->
  <div class="container text-center text-md-left">

    <!--First row-->
    <div class="row">

      <!--First column-->
      <div class="col-md-4 mx-auto">

        <!--Content-->
        
        <img src="assets/footer-logo.png" class="img-fluid mt-3" alt="Wenzke-Consulting" title="Wenzke-Consulting Logo">
      </div>
      <!--./First column-->

      <!--Divider-->
      <hr class="clearfix w-100 d-md-none">

      <!--Second column-->
      <div class="col-md-4 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Über mich</h5>

        <ul class="list-unstyled">
          <li>
            <a routerLink="management" title="Vorstellung der Geschäftsführung">Geschäftsführung</a>
          </li>
          <li>
            <a routerLink="kontakt" title="AGB von Wenzke-Consulting">Kontakt</a>
          </li>
          <li>
            <a routerLink="referenzen" title="AGB von Wenzke-Consulting">Referenzen</a>
          </li>
        </ul>

      </div>
      <!--./Second column-->

      <!--Divider-->
      <hr class="clearfix w-100 d-md-none">

      <!--Third column-->
      <div class="col-md-4 mx-auto">

        <!-- Links-->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Rechtliches</h5>

        <ul class="list-unstyled">
          <li>
            <a routerLink="agb" title="AGB von Wenzke-Consulting">AGB</a>
          </li>
          <li>
            <a routerLink="datenschutz" title="Datenschutzerklärung von Wenzke-Consulting">Datenschutz</a>
          </li>
          <li>
            <a routerLink="impressum" title="Impressum von Wenzke-Consulting">Impressum</a>
          </li>
          <li>
            <a href="#" id="open_preferences_center">Cookie Einstellungen</a>
          </li>
        </ul>

      </div>
      <!--./Third column-->

      <!--Divider-->
      <hr class="clearfix w-100 d-md-none">

    </div>
    <!--./First row-->

  </div>
  <!--./Footer Links-->

  <hr>

  <!-- Call to action-->
  <ul class="list-unstyled list-inline text-center py-2">
    <li class="list-inline-item">
      <h5 class="mb-1">Kontaktieren Sie mich</h5>
    </li>
    <li class="list-inline-item">
      <a href="mailto:info@wenzke-consulting.de?subject=Anfrage" class="btn btn-danger btn-rounded">Jetzt!</a>
    </li>
  </ul>
  <!--./Call to action-->

  <hr>

  <!--Social buttons-->
  <ul class="list-unstyled list-inline text-center">
    <li class="list-inline-item">
      <a class="btn-floating btn-fb mx-1" title="Verweis auf meine Facebook-Seite" href="https://www.facebook.com/wenzkeconsulting"
        target="_blank">
        <i class="fab fa-facebook-f"></i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-tw mx-1" title="Verweis auf meine Twitter-Seite" href="https://twitter.com/wenzkeconsult" target="_blank">
        <i class="fab fa-twitter"></i>
      </a>
    </li>
    <!-- not really needed? -->
    <!--<li class="list-inline-item">
      <a class="btn-floating btn-gplus mx-1" href="https://google.com/+WenzkeConsultingOberschlei%C3%9Fheim" target="_blank" title="Verweis auf unsere Google-Plus Seite">
        <i class="fab fa-google-plus-g"></i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-li mx-1" title="Verweis auf unsere LinkedIn-Seite">
        <i class="fab fa-linkedin-in"></i>
      </a>
    </li>-->
    <li class="list-inline-item">
      <a class="btn-floating btn-xing mx-1" href="https://www.xing.com/profile/Patrick_Wenzke" target="_blank" title="Verweis auf meine XING-Seite">
        <i class="fab fa-xing"></i>
      </a>
    </li>
  </ul>
  <!--./Social buttons-->

  <!-- Copyright-->
  <div class="footer-copyright text-center py-3">© {{currentYear}} Copyright:
    <a href="https://www.wenzke-consulting.de" title="Verweis auf die Webseite von Wenzke-Consulting"> Wenzke-Consulting.de</a>
  </div>
  <!--./Copyright-->

</footer>
<!--./Footer-->