<div class="view withoutBackgroundPicture">

  <div class="container">

    <section class="content-section">

      <div class="row">

        <div class="col-lg-12 agb-text">

          <h1>Allgemeine Geschäftsbedingungen</h1>
          <p>Allgemeine Geschäftsbedingungen von Wenzke-Consulting</p>

          <p><b>§ 1 Geltungsbereich</b></p>

          <p>1. Die nachstehenden allgemeinen Geschäftsbedingungen
            gelten für alle Rechtsgeschäfte der Patrick Wenzke Consulting, nachstehend „Wenzke-Consulting“, nach
            diesem Vertrag mit seinem Vertragspartner, nachstehend „Kunde” genannt.</p>

          <p>2. Änderungen dieser Geschäftsbedingungen werden dem
            Kunden schriftlich bekannt gegeben. Sie gelten als genehmigt, wenn der Kunde nicht schriftlich
            Widerspruch erhebt. Der Kunde muss den Widerspruch innerhalb von 2 Wochen nach Bekanntgabe der Änderungen
            an Wenzke-Consulting absenden.</p>

          <p><b>§ 2 Gegenstand und Dauer des Vertrages, Kündigungsfristen, Abnahme</b></p>

          <p>1. Wenzke-Consulting erbringt Dienstleistungen aus den Bereichen JIRA, Online-Marketing,
            Software-Entwicklung, IT-Projektmanagement, Suchmaschinenoptimierung und Web Design.</p>

          <p>2. Von diesen Geschäftsbedingungen abweichende Bedingungen
            des Kunden werden von Wenzke-Consulting nur nach gesonderter und schriftlicher Anerkennung akzeptiert.</p>

          <p>3. Diese Geschäftsbedingungen gelten auch für alle künftigen
            Geschäftsbeziehungen, auch wenn sie nicht nochmals ausdrücklich vereinbart werden.</p>

          <p>4. Alle Vereinbarungen, die zwischen Wenzke-Consulting und dem
            Kunden zwecks Ausführung eines Auftrages getroffen werden, sind in schriftlicher Form zu vereinbaren.
            Änderungen, Ergänzungen und Nebenabreden bedürfen zu ihrer Wirksamkeit der Schriftform.</p>

          <p>5. Jedem Vertrag (Projektvereinbarung) liegt eine
            detaillierte Leistungsbeschreibung bei, die den Inhalt der geschuldeten Leistungen definiert.</p>

          <p>6. Der Vertrag (Projektvereinbarung) tritt mit seiner
            Unterzeichnung in Kraft. Er wird für die im Vertrag (Projektvereinbarung), bzw. in dem dazugehörigen
            Angebot genannte Vertragslaufzeit abgeschlossen. Ist der Vertrag auf unbestimmte Zeit geschlossen, kann
            dieser mit einer Frist von drei Monaten von beiden Seiten zum Jahresende gekündigt werden. Ist in dem
            Vertrag (Projektvereinbarung) keine Vertragslaufzeit angegeben, gilt die Vollendung des Auftrages durch
            Wenzke-Consulting als Vertragsende. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt von
            dieser
            Regelung unberührt. Eine Kündigung bedarf der Schriftform.</p>

          <p>7. Sofern keine der Vertragsparteien eine förmliche Abnahme
            verlangt, oder sofern der von einer Partei verlangte Abnahmetermin aus einem Umstand nicht zustande
            kommt, der vom Kunden zu vertreten ist, gilt die vertragliche Leistung von Wenzke-Consulting mit Nutzung
            durch
            den Kunden als abgenommen.</p>

          <p>8. Individuell programmierte Lösungen werden dem Kunden auf
            einem Server von Strato zum Test und der vorbereitenden Übergabe bereitgestellt. Nach technischer
            Freigabe des Kunden gilt die Leistung Wenzke-Consulting als abgenommen und die Rechnung ist vor Übergabe
            der
            Daten an den Kunden zu begleichen. Dem Kunden ist gestattet, 5% des Auftragswertes als Sicherheitsbehalt
            bis zur vollständigen Übergabe des Produktes einzubehalten. Diese Summe wird innerhalb von fünf Werktagen
            nach Übergabe ohne erneute Rechnung fällig.</p>

          <p><b>§ 3 Abnahme erstellter Software und Websites</b></p>

          <p>1. Nach vollständiger Übergabe und Installation der
            fertig gestellten Software wird eine zweiwöchige Testphase vereinbart. Diese beginnt mit der vollendeten
            Installation der Software oder Bereitstellung der Weebseite. Die Testphase ermöglicht dem Kunden eine
            Überprüfung
            der Funktionsfähigkeit der Vertragssoftware sowie des Layouts und auf etwaige sonstige Mängel hin.</p>

          <p>2. Der Kunde wird während der Testphase auftretende Fehler
            der Vertragssoftware Wenzke-Consulting schriftlich anzeigen. Wenzke-Consulting steht dem Kunden auch
            während der
            Testphase zur Verfügung, um gerügte Mängel der Vertragssoftware unverzüglich zu untersuchen und zu
            beheben.</p>

          <p>3. Sollten noch während der Testphase Fehler der Software
            auftreten und zeigt der Kunde diese Fehler Wenzke-Consulting schriftlich an, so verlängert sich die
            Testphase
            bis zur Behebung des Fehlers und um eine sich daran anschließende angemessene Prüfungsfrist.</p>

          <p>4. Treten während der Testphase auch während eines
            Lastbetriebes keine wesentlichen Fehler auf oder werden Wenzke-Consulting keine wesentlichen Fehler
            schriftlich
            angezeigt, so wird der Kunde eine schriftliche Erklärung abgeben, dass die fertig gestellte
            Vertragssoftware in vertragsgemäßem Zustand installiert worden ist (Abnahme). Wenzke-Consulting übernimmt
            keine
            Verantwortung für den Server, die Datenleitungen, den Internet-Zugang der Nutzer etc.</p>

          <p>5. § 2 Ziffer 8 Satz 3 gilt entsprechend.</p>

          <p><b>§ 4 Urheber- und Nutzungsrechte</b></p>

          <p>1. Der Kunde erwirbt mit der vollständigen Zahlung des
            vereinbarten Honorars für die vertraglich vereinbarte Dauer und im vertraglich vereinbarten Umfang die
            Nutzungsrechte an allen von Wenzke-Consulting im Rahmen dieses Auftrages gefertigten Arbeiten. Diese
            Übertragung der Nutzungsrechte gilt soweit eine Übertragung nach deutschem Recht möglich ist und gilt für
            die vereinbarte Nutzung im Gebiet der Bundesrepublik Deutschland. Nutzungen die über dieses Gebiet
            hinausgehen, bedürfen einer schriftlichen Vereinbarung im Rahmen des Auftrages oder einer gesonderten
            schriftlichen Nebenabrede. Nutzungsrechte an Arbeiten, die bei Beendigung des Vertrages noch nicht
            bezahlt sind, verbleiben vorbehaltlich anderweitig getroffener Abmachungen bei Wenzke-Consulting.</p>

          <p>2. Entwickelte Entwürfe sind bis zur vollständigen Erfüllung
            eines Vertrages und der Übergabe des finalen Produktes an den Kunden Eigentum Wenzke-Consulting,
            unterliegen
            dem alleinigen Urheber- und Nutzungsrecht Wenzke-Consulting und dürfen nicht an Dritte weitergegeben
            werden.
            Dies gilt auch für Entwürfe, die im Rahmen kostenloser Marketingaktionen oder für eine
            Angebotspräsentation erstellt wurden. Entwürfe dienen dem Kunden als Veranschaulichung von zu
            erbringenden Leistungen und dürfen ohne schriftliche Einwilligung Wenzke-Consulting, auch in inhaltlich
            oder
            äußerlich abgewandelter Form, nicht kopiert, vervielfältigt oder veröffentlicht werden.</p>

          <p>3. Die im Rahmen des Auftrages erarbeiteten Leistungen sind
            als persönliche geistige Schöpfungen durch das Urheberrechtsgesetz geschützt.</p>

          <p>4. Wenzke-Consulting darf die von ihr entwickelten Werbemittel
            angemessen und branchenüblich signieren und den erteilten Auftrag für Eigenwerbung publizieren. Diese
            Signierung und werbliche Verwendung kann durch eine entsprechende gesonderte schriftliche Vereinbarung
            zwischen Wenzke-Consulting und dem Kunden vor Auftragsvergabe ausgeschlossen werden.</p>

          <p>5. Die Arbeiten Wenzke-Consulting dürfen vom Kunden oder vom Kunden
            beauftragter Dritter weder im Original noch bei der Reproduktion geändert werden. Jede Nachahmung, auch
            die von Teilen des Werkes, ist unzulässig. Bei Zuwiderhandlung steht Wenzke-Consulting vom Kunden ein
            zusätzliches Honorar in mindestens der 2 fachen Höhe des ursprünglich vereinbarten Honorars zu.</p>

          <p>6. Die Übertragung eingeräumter Nutzungsrechte an Dritte
            und/oder Mehrfachnutzungen sind, soweit nicht im Erstauftrag geregelt, honorarpflichtig und bedürfen der
            Einwilligung Wenzke-Consulting.</p>

          <p><b>§ 5 Vergütung</b></p>

          <p>1. Es gilt die im Vertrag vereinbarte Vergütung. Zahlungen
            sind, wenn nicht anders vertraglich geregelt, innerhalb von 14 Tagen nach Rechnungsstellung ohne jeden
            Abzug fällig. Bei Zahlungsverzug von mehr als 10 Kalendertagen ist Wenzke-Consulting berechtigt, den
            Gesamtauftragswert sofort und ohne Abzug von dem Kunden einzufordern. Das Recht zur Geltendmachung eines
            darüber hinausgehenden Schadens bleibt von dieser Regelung unberührt.</p>
          <p>2. Erstreckt sich die Erarbeitung der vereinbarten Leistungen
            über einen längeren Zeitraum, so kann Wenzke-Consulting dem Kunden Abschlagszahlungen über die bereits
            erbrachten Teilleistungen in Rechnung stellen, auch wenn dieses nicht explizit im Vertrag
            (Projektvereinbarung) definiert wurde. Diese Teilleistungen müssen nicht in einer für den Kunden
            nutzbaren Form vorliegen und können auch als reine Arbeitsgrundlage auf Seiten Wenzke-Consulting verfügbar
            sein.</p>

          <p>3. Bei Dienstleistungsprojekten (Programmier-,
            Gestaltungsarbeiten, o. ä.) behält sich Wenzke-Consulting das Recht vor, den Gesamtbetrag (Restbetrag bei
            Raten- oder Abschlagszahlungen) vor Übergabe der Leistung von dem Kunden einzufordern.</p>

          <p>4. Bei Änderungen oder Abbruch von Aufträgen, Arbeiten und
            der gleichen durch den Kunden und/oder wenn sich die Voraussetzungen für die Leistungserstellung ändert,
            wird der Kunde Wenzke-Consulting alle dadurch anfallenden Kosten ersetzen und selbige von jeglichen
            Verbindlichkeiten gegenüber Dritten freistellen.</p>

          <p>5. Bei Überschreitung eines Zahlungszieles um mehr als 30
            Werktage, behält sich Wenzke-Consulting das Recht vor, Projekte vorzeitig und ohne Vollendung zu kündigen.
            Wenzke-Consulting ist auch ohne Fertigstellung des Projektes berechtigt, den Gesamtbetrag ohne Abzug sofort
            einzufordern. Bei laufenden Projekten mit monatlicher Zahlung, ist Wenzke-Consulting berechtigt den
            Gesamtbetrag bis zum Laufzeitende vollständig einzufordern.</p>

          <p>6. Alle in Angeboten und Aufträgen genannte Preise und die
            daraus resultierend zu zahlende Beträge verstehen sich zuzüglich der gesetzlich gültigen Umsatzsteuer in
            der jeweils geltenden Höhe.</p>

          <p><b>§ 6 Zusatzleistung</b></p>

          <p>Unvorhersehbarer Mehraufwand bedarf der gegenseitigen Absprache und gegebenenfalls der Nachhonorierung.</p>

          <p><b>§ 7 Zeitplanung</b></p>
          <p>Ausschließlich in der unterzeichneten Projektvereinbarung (nebst zugehörigem Angebot) genannte Timings
            sind für beide Parteien verbindlich. Nebenabreden, auch schriftlich, stellen allenfalls eine zeitliche
            Einschätzung dar und machen das Projekt auch bei deutlichem Überschreiten nicht abzugsfähig.</p>

          <p><b>§ 8 Geheimhaltung</b></p>
          <p>1. Wenzke-Consulting ist verpflichtet, alle Kenntnisse die sie
            aufgrund eines Auftrags vom Kunden erhält, zeitlich unbeschränkt streng vertraulich zu behandeln und
            sowohl ihre Mitarbeiter, als auch von ihr herangezogene Dritte ebenfalls in gleicher Weise zu absolutem
            Stillschweigen zu verpflichten.</p>
          <p>2. Der Kunde verpflichtet sich, alle ihm bei der
            Vertragsdurchführung von Wenzke-Consulting oder im Auftrag Wenzke-Consulting handelnden Personen zugehenden
            oder
            bekannt werdenden Geschäfts- und Betriebsgeheimnisse oder als vertraulich bezeichnete Informationen
            geheim zu halten. Dies gilt insbesondere für Informationen über Suchgewohnheiten und Technologie der
            Suchmaschinen, soweit diese nicht allgemein bekannt sind. Diese Verpflichtung gilt zudem auch zwei Jahre
            über das Vertragsende hinaus.</p>

          <p><b>§ 9 Pflichten des Kunden</b></p>
          <p>1. Der Kunde stellt Wenzke-Consulting alle für die Durchführung des
            Projekts benötigten Daten und Unterlagen unentgeltlich zur Verfügung. Alle Arbeitsunterlagen werden von
            Wenzke-Consulting sorgsam behandelt, vor dem Zugriff Dritter geschützt, nur zur Erarbeitung des jeweiligen
            Auftrages genutzt und werden nach Beendigung des Auftrages an den Kunden zurück gegeben oder vernichtet.</p>
          <p>2. Der Kunde wird im Zusammenhang mit einem beauftragten
            Projekt Auftragsvergaben an andere Agenturen oder Dienstleister nur nach Rücksprache und im Einvernehmen
            mit Wenzke-Consulting erteilen.</p>

          <p><b>§ 10 Mitwirkung</b></p>

          <p>1. Der Kunde ist verpflichtet, alle notwendigen
            Mitwirkungsleistungen zu erbringen, damit Wenzke-Consulting die vertragliche Leistung durchführen kann.
            Insbesondere wird er alle für die Vertragsdurchführung erforderlichen Informationen erteilen.</p>

          <p>2. Der Kunde ist verpflichtet, alle Leistungen Wenzke-Consulting
            innerhalb von 5 Werktagen zu untersuchen und Mängel schriftlich unter genauer Beschreibung zu
            rügen. Nimmt Wenzke-Consulting auf Anforderungen des Kunden die Fehlersuche vor und stellt sich heraus,
            dass keine
            Fehler oder Fehler außerhalb des Verantwortungsbereiches Wenzke-Consulting vorliegen, kann selbige den
            Aufwand
            in Rechnung stellen.</p>

          <p>3. Kommt der Kunde seinen Mitwirkungspflichten nicht nach,
            ist Wenzke-Consulting von der Leistungspflicht befreit. Leistet Wenzke-Consulting dennoch, stellt sie den
            Aufwand
            entsprechend der gültigen Preisliste in Rechnung.</p>

          <p>4. Sollte Wenzke-Consulting von Seiten des Kunden kein FTP-Zugriff
            oder andere Anforderungen gewährt werden, trägt der Kunde eventuell anfallende Kosten durch Aufwendungen
            eines Dritten (z.B. Internetagenturen oder Provider).</p>

          <p><b>§ 11 Mitwirkungspflichten bei Website-Erstellung</b></p>

          <p>1. Sofern die Beauftragung durch den Kunden die Erstellung
            einer Website zum Gegenstand hat, stellt der Kunde Wenzke-Consulting eigenverantwortlich die zur Erstellung
            der
            Website erforderlichen Inhalte zur Verfügung. Wenzke-Consulting ist nicht verpflichtet, die vom Kunden zur
            Verfügung gestellten Inhalte zu überprüfen, insbesondere nicht im Hinblick darauf, ob sie geeignet sind,
            den mit der Erstellung der Website verfolgten Zweck zu erreichen.</p>

          <p>2. Zu den vom Kunden bereitzustellenden Inhalten gehören
            insbesondere alle nach dem Wunsch des Kunden zu verwendenden Texte, Photographien, Grafiken und Tabellen.</p>

          <p>3. Die in Ziffer 1 und 2 umschriebenen Daten werden Wenzke-Consulting in digitaler Form zur Verfügung
            gestellt.</p>

          <p><b>§ 12 Webhosting, Software</b></p>

          <p>1. Webhosting (E-Mail): Der Kunde hat für ihn über das
            Internet eingehende Nachrichten in regelmäßigen Abständen von höchstens einer Woche abzurufen und auf
            eigenen Rechnern zu speichern.</p>

          <p>2. Wenzke-Consulting behält sich vor, für den Kunden eingegangene
            persönliche Nachrichten nach 3 Monaten ohne Rückfrage zu löschen. Der Kunde verpflichtet sich, von
            Wenzke-Consulting zum Zwecke des Zugangs zu deren Diensten erhaltene Passwörter streng geheim zu halten und
            den
            Provider unverzüglich zu informieren, sobald er davon Kenntnis erlangt, dass unbefugten Dritten das
            Passwort bekannt ist. Sollten infolge Verschuldens des Kunden Dritte durch Missbrauch der Passwörter
            Leistungen Wenzke-Consulting nutzen, haftet der Kunde gegenüber Wenzke-Consulting auf Nutzungsentgelt und
            Schadensersatz.</p>

          <p>3. Der Kunde wird darauf hingewiesen, dass es ihm obliegt, in
            regelmäßigen Abständen, mindestens täglich, eine Datensicherung durchzuführen, wobei Daten, die auf den
            von Wenzke-Consulting gehosteten Web-Servern abgelegt sind, nicht auf diesen sicherungsgespeichert werden
            dürfen.</p>

          <p>4. Der Kunde hat eine vollständige Datensicherung
            insbesondere vor jedem Beginn von Arbeiten Wenzke-Consulting oder vor der Installation von gelieferter
            Hard-
            oder Software durchzuführen.</p>

          <p>5. Webhosting (Internet): Der Kunde darf mit Form, Inhalt
            oder verfolgtem Zweck seiner Internetseiten nicht gegen gesetzliche Verbote, die guten Sitten und Rechte
            Dritter (Namens-, Urheber-, Datenschutzrechte usw.) verstoßen. Insbesondere verpflichtet sich der Kunde,
            im Rahmen seiner Präsenz keine pornographischen Inhalte und keine auf Gewinnerzielung gerichteten
            Leistungen anzubieten oder anbieten zu lassen, die pornografische und/oder erotische Inhalte zum
            Gegenstand haben.</p>

          <p>6. Im Falle eines unmittelbar drohenden oder
            eingetretenen Verstoßes gegen die vorstehenden Verpflichtungen sowie bei der Geltendmachung nicht
            offensichtlich unbegründeter Ansprüche Dritter gegen Wenzke-Consulting auf Unterlassen der vollständigen
            oder
            teilweisen Darbietung der auf dem Server abgelegten Inhalte über das Internet ist Wenzke-Consulting
            berechtigt,
            unter Berücksichtigung auch der berechtigten Interessen des Kunden die Anbindung dieser Inhalte an das
            Internet ganz oder teilweise mit sofortiger Wirkung vorübergehend einzustellen. Wenzke-Consulting wird den
            Kunden über diese Maßnahme unverzüglich informieren.</p>

          <p>7. Bei Verstoß der Internetseiten des Kunden gegen
            gesetzliche Verbote, die guten Sitten oder Rechte Dritter haftet der Kunde gegenüber Wenzke-Consulting auf
            Ersatz aller hieraus entstehenden direkten und indirekten Schäden, auch des Vermögensschadens. Er stellt
            Wenzke-Consulting im Innenverhältnis von etwaigen Ansprüchen Dritter, die auf Inhalte von Internetseiten
            des
            Kunden zurückgehen, frei. Wenzke-Consulting übernimmt keine Gewähr für die richtige Wiedergabe der
            Internetseiten des Kunden in der Internetpräsenz, es sei denn, Wenzke-Consulting kann Vorsatz oder grobe
            Fahrlässigkeit zur Last gelegt werden. Für mittelbare Schäden und Folgeschäden sowie für entgangenen
            Gewinn haftet Wenzke-Consulting nur bei Vorsatz.</p>

          <p>8. Der Kunde hat Wenzke-Consulting bei einer möglichen
            Mangelbeseitigung nach Kräften zu unterstützen. Der Kunde hat vor einer Fehlerbeseitigung, insbesondere
            vor einem Rechneraustausch, Programme, Daten und Datenträger vollständig zu sichern, erforderlichenfalls
            zu entfernen.</p>

          <p>9. Suchmaschinenoptimierung: Wenzke-Consulting erbringt
            Dienstleistungen im Bereich Suchmaschinenmarketing mit dem Ziel, die Webseiten seiner Kunden in
            Suchdiensten besser zu platzieren. Wenzke-Consulting optimiert vereinbarte Webseiten des Auftraggebers im
            Hinblick auf eine verbesserte Positionierung in den Suchergebnisseiten der wichtigsten Suchdienste
            während der vereinbarten Vertragslaufzeit. Die Optimierung wird für mit dem Kunden vereinbarte
            Suchbegriffe und Suchbegriffskombinationen, im folgenden Keywords genannt, vorgenommen. Eine Garantie für
            die Verbesserung der Suchergebnisse gibt Wenzke-Consulting nicht. Der Kunde wird ausdrücklich darauf
            hingewiesen, dass jede, auch nur kleinste eigenmächtige Veränderung an den optimierten Webseiten oder der
            eingehenden Hyperlinks die gesamte Optimierung Wenzke-Consulting zunichtemachen kann.</p>

          <p><b>§ 13 Gewährleistung und Haftung / Rechte Dritter </b></p>

          <p>1. Das Risiko der rechtlichen Zulässigkeit der durch Wenzke-Consulting erarbeiteten und durchgeführten
            Maßnahmen wird vom Kunden getragen. Das gilt insbesondere
            für den Fall, dass die Aktionen und Maßnahmen gegen Vorschriften des Wettbewerbsrechts, des Urheberrechts
            und der speziellen Werberechtsgesetze verstoßen.</p>

          <p>Der Auftraggeber garantiert, dass die von ihm zur Verfügung gestellten Inhalte und Informationen nicht
            in rechtswidriger Weise in Rechte Dritter eingreifen. Er stellt Wenzke-Consulting hiermit von jeglichen
            Ansprüchen in diesem Zusammenhang frei und ersetzt ihm die angemessenen Kosten der Rechtsverteidigung.</p>

          <p>2 Wenzke-Consulting haftet in keinem Fall wegen der in den
            Werbemaßnahmen enthaltenen Sachaussagen über Produkte und Leistungen des Kunden. Wenzke-Consulting haftet
            auch
            nicht für die patent-, urheber- und markenrechtliche Schutz oder Eintragungsfähigkeit der im Rahmen des
            Auftrages gelieferten Ideen, Anregungen, Vorschläge, Konzeptionen und Entwürfe.</p>

          <p>3. Wenzke-Consulting haftet in keinem Fall für datenschutzrechtlich
            kritische Applikationen, Skripte und Software, die auf Kundenwunsch in Websites oder auf Rechensystemen
            des Kunden installiert wurden und ist nicht für die Bereitstellung von Datenschutzerklärungen auf von
            Wenzke-Consulting entwickelten Websites verantwortlich, wird den Kunden aber auf diese Pflicht hinweisen.</p>

          <p>4. Wenzke-Consulting haftet nur für Schäden, die sie oder ihre
            Erfüllungsgehilfen vorsätzlich oder grob fahrlässig herbeigeführt haben. Im Fall einfacher Fahrlässigkeit
            haftet Wenzke-Consulting nur bei Verletzung vertragswesentlicher Pflichten oder von Leben, Körper oder
            Gesundheit einer Person. Diese Haftungsreduktion gilt auch für das Verschulden von Erfüllungsgehilfen.
            Die Haftung Wenzke-Consulting wird in der Höhe beschränkt auf die Vergütung Wenzke-Consulting, die sich aus
            dem
            jeweiligen Auftrag ergibt. Diese Haftungsbeschränkung gilt auch für Folgeschäden im Fall einfacher
            Fahrlässigkeit, mit Ausnahme von Lebens-, Körper- oder Gesundheitsverletzungen.</p>

          <p>5. Wenzke-Consulting haftet in keinem Fall für Schäden, Ausfälle
            oder Umsatzeinbußen, die bei der programmiertechnischen Arbeit an Livesystemen des Kunden durchgeführt
            werden. Das Risiko kann durch den Kunden minimiert werden, wenn er die Arbeit auf zusätzlichen
            Entwicklungsumgebung ermöglicht/beauftragt. Dies kann mit deutlichen Mehrkosten verbunden sein.</p>

          <p>6. Von der Gewährleistung ausgeschlossen sind grundsätzlich
            solche Fehler, die durch äußere Einflüsse, Bedienungsfehler oder nicht von Wenzke-Consulting durchgeführte
            Änderungen, Ergänzungen oder sonstige Manipulationen entstehen.</p>

          <p>7. Die Gewährleistungs- und Nachbesserungspflicht Wenzke-Consulting
            für Programmierleistungen und Softwareprodukte erlischt vollständig, wenn der Kunde Zugangsberechtigungen
            für Administrationsebenen oder Quelldateien fordert, die nicht für das allgemeine Arbeiten mit dem
            Produkt erforderlich sind und/oder diese Dritten zugänglich macht.</p>

          <p>8. Wenzke-Consulting haftet bei der Nichterfüllung von
            verbindlichen und in der Projektvereinbarung vereinbarten Zeitplänen nur bis zum Auftragswert. Kleinere
            Fehler bei oder nach Projektübergabe gelten nicht als Nichterfüllung eines Zeitplanes. Wenzke-Consulting
            haftet
            in keinem Fall für Schäden, die aufgrund einer länger als geplanter Umsetzungsdauer entstehen, sofern
            diese nicht vertraglich vereinbart war.</p>

          <p>9. Die Gewährleistungszeit im Falle der Erstellung einer
            Website durch Wenzke-Consulting beträgt 12 Monate beginnend mit der vollständigen Abnahme der Website.</p>

          <p>10. Wenzke-Consulting haftet lediglich für die einwandfreie Erreichbarkeit
            des Internetauftritts im World Wide Web zum Zeitpunkt der Abnahme unter Verwendung der gängigsten
            Internetbrowser in deren jeweils aktuellen Version, insbesondere der folgenden Browser:</p>
          <p>– Google Chrome</p>
          <p>– Mozilla Firefox</p>
          <p>– Safari</p>

          <p><b>§ 14 Arbeitsunterlagen und elektronische Daten</b></p>
          <p>Alle Arbeitsunterlagen, elektronische Daten und Aufzeichnungen die im Rahmen der Auftragserarbeitung auf
            Seiten von Wenzke-Consulting angefertigt werden, verbleiben bei selbiger. Die Herausgabe dieser Unterlagen
            und
            Daten kann vom Kunden nicht gefordert werden. Wenzke-Consulting schuldet mit der Bezahlung des vereinbarten
            Honorars die vereinbarte Leistung, nicht jedoch die zu diesem Ergebnis führenden Zwischenschritte in Form
            von Skizzen, Entwürfen, Produktionsdaten etc.</p>

          <p><b>§ 15 Datenschutz</b></p>
          <p>1. Wenzke-Consulting speichert alle Daten des Kunden während der
            Dauer des Vertragsverhältnisses elektronisch, soweit dies zur Erfüllung des Vertragszwecks, insbesondere
            für Abrechnungszwecke, erforderlich ist. Die erhobenen Bestandsdaten verarbeitet und nutzt
            Wenzke-Consulting
            auch zur Beratung ihrer Kunden, zur Werbung und zur Marktforschung für eigene Zwecke und zur
            bedarfsgerechten Gestaltung seiner Telekommunikationsleistungen. Wenzke-Consulting wird dem Kunden auf
            Verlangen jederzeit über den gespeicherten Datenbestand, soweit er ihn betrifft, vollständig und
            unentgeltlich Auskunft zu erteilen.</p>

          <p>2. Wenzke-Consulting wird weder diese Daten noch den Inhalt
            privater Nachrichten des Kunden ohne dessen Einverständnis an Dritte weiterleiten. Dies gilt insoweit
            nicht, als Wenzke-Consulting verpflichtet ist, Dritten, insbesondere staatlichen Stellen, solche Daten zu
            offenbaren oder soweit international anerkannte technische Normen dies vorsehen und der Kunde nicht
            widerspricht.</p>

          <p>3. Wenzke-Consulting weist den Kunden ausdrücklich darauf hin, dass
            der Datenschutz für Datenübertragungen in offenen Netzen wie dem Internet nach dem derzeitigen Stand der
            Technik nicht umfassend gewährleistet werden kann.</p>

          <p>4. Der Kunde weiß, dass der Provider das auf dem Webserver
            gespeicherte Seitenangebot und unter Umständen auch weitere dort abgelegte Daten des Kunden aus
            technischer Sicht jederzeit einsehen kann. Auch andere Teilnehmer am Internet sind unter Umständen
            technisch in der Lage, unbefugt in die Netzsicherheit einzugreifen und den Nachrichtenverkehr zu
            kontrollieren. Für die Sicherheit der von ihm ins Internet übermittelten und auf Web-Servern
            gespeicherten Daten trägt der Kunde vollumfänglich selbst Sorge.</p>

          <p><b>§ 16 Widerrufsrecht</b></p>

          <p>Als Verbraucher haben Sie ein Widerrufsrecht. Hinsichtlich Voraussetzungen und Folgen des
            Widerrufsrechts verweisen wir auf die Widerrufsbelehrung im Anhang.</p>

          <p><b>§ 17 Schlussbestimmungen</b></p>

          <p>1.</p>
          <p>Der Kunde ist nicht dazu berechtigt, Ansprüche aus dem Vertrag abzutreten.</p>
          <p>2. Eine Aufrechnung oder die Geltendmachung eines
            Zurückbehaltungsrechts durch den Kunden ist nur mit anerkannten oder rechtskräftig festgestellten
            Gegenansprüchen zulässig.</p>

          <p>3. Es gilt das Recht der Bundesrepublik Deutschland.
            Erfüllungsort und Gerichtsstand ist München. Die Gerichtsstandvereinbarung gilt nicht für Verbraucher.</p>

          <p>4. Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit
            einzelner Punkte in seinen übrigen Teilen verbindlich. Anstelle der unwirksamen Punkte treten, soweit
            vorhanden, die gesetzlichen Vorschriften. Soweit dies für eine Vertragspartei eine unzumutbare Härte
            darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam.</p>

          <p>5. Ereignisse höherer Gewalt berechtigen Wenzke-Consulting, das vom
            Kunden beauftragte Projekt um die Dauer der Behinderung und einer angemessenen Anlaufzeit
            hinauszuschieben. Ein Schadensersatzanspruch vom Kunden gegen Wenzke-Consulting resultiert daraus nicht.
            Dies
            gilt auch dann, wenn dadurch für den Kunden wichtige Termine und/oder Ereignisse nicht eingehalten werden
            können und/oder nicht eintreten.</p>

          <p><b>§ 18 Salvatorische Klausel</b></p>
          <p>Die Unwirksamkeit einer Bestimmung dieser AGB hat keine Auswirkungen auf die Wirksamkeit der sonstigen
            Bestimmungen.</p>

          <p><b>Anhang</b></p>

          <p><b>Widerrufsbelehrung</b></p>

          <p><b>Widerrufsrecht</b></p>

          <p>Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform (z. B.
            Brief, Fax, E-Mail) widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht
            vor Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 § 2 in
            Verbindung mit § 1 Abs. 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312 e Abs. 1 Satz 1 BGB in
            Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung
            des Widerrufs. Der Widerruf ist zu richten an:</p>

          <p>Patrick Wenzke Consulting</p>
          <p>Professor-Otto-Hupp-Str.9</p>
          <p>85764 Oberschleißheim</p>
          <p>Fax: +49 89 23032665</p>
          <p>E-Mail: <a href="mailto:info@wenzke-consulting.de">info&#64;wenzke-consulting.de</a></p>

          <p><b>Widerrufsfolgen</b></p>

          <p>Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf.
            gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung ganz oder
            teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren, müssen Sie uns insoweit ggf.
            Wertersatz leisten. Dies kann dazu führen, dass Sie die vertraglichen Zahlungsverpflichtungen für den
            Zeitraum bis zum Widerruf gleichwohl erfüllen müssen. Verpflichtungen zur Erstattung von Zahlungen müssen
            innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer
            Widerrufserklärung, für uns mit deren Empfang.</p>

          <p><b>Besondere Hinweise</b></p>
          <p>Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen
            Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben.</p>

        </div>

      </div>

    </section>

  </div>

</div>