<div class="view withoutBackgroundPicture">

  <div class="container">

    <section class="content-section">

      <div class="row">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1>Referenzen</h1>
          <p>
            Begeistert von meiner Leistung! Zufriedene Kunden von
            Patrick Wenzke Consulting
          </p>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-12">

          <div class="card-deck">

            <!--Altusried Card-->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.2s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Tourismusverein-Altusried</h4>
                <!-- Price -->
                <a href="https://www.tourismusverein-altusried.de" target="_blank" title="Tourismusverein im Allgäu">
                  <img src="assets/altusried.jpg" class="img-fluid" alt="Tourismusverein Altusried" />
                </a>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Beratung & Konzeption</p>
                  </li>
                  <li>
                    <p>
                      Entwicklung einer Portallösung</p>
                  </li>
                  <li>
                    <p>
                      Veröffentlichung im Google Play</p>
                  </li>
                  <li>
                    <p>
                      Verwaltung von Ferienhäusern</p>
                  </li>

                </ul>
              </div>
            </div>
            <!--/.Card-->

            <!--Hemp Society -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">LHAW Development UG</h4>
                <!-- Price -->
                <a href="https://lhaw.de/" target="_blank" title="LHAW UG">
                  <img src="assets/lhaw.png" class="img-fluid" alt="LHAW UG" />
                </a>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Einrichtung von JIRA Software</p>
                  </li>
                  <li>
                    <p>
                      Einrichtung von JIRA ServiceDesk</p>
                  </li>

                </ul>
              </div>

            </div>
            <!--/.Card-->

            <!--LHAW -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">PPMeinhold Immobilien</h4>
                <!-- Price -->
                <img src="assets/meinhold.jpg" class="img-fluid" alt="P.P.Meinhold Immobilien"
                  title="Immobilienmakler in München" />
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Synchronisationsverfahren</p>
                  </li>
                  <li>
                    <p>
                      Netzwerkbetreuung</p>
                  </li>
                  <li>
                    <p>Serveradministration</p>
                  </li>

                </ul>
              </div>

            </div>
            <!--/.Card-->

          </div>
          <!--./Card Deck-->

          <br />

          <div class="card-deck">

            <!--Altusried Card-->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.2s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Privatpraxis Dr. Klinghammer
                </h4>
                <!-- Price -->
                <img src="assets/klinghammer.png" class="img-fluid" alt="Privatpraxis Dr. Klinghammer"
                  title="Arztpraxis in Köln" />
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Beratung & Konzeption</p>
                  </li>
                  <li>
                    <p>
                      Erstellung einer Internetpräsenz</p>
                  </li>

                </ul>
              </div>
            </div>
            <!--/.Card-->

            <!--Hemp Society -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Ford Strunk</h4>
                <!-- Price -->
                <img src="assets/strunk.jpg" class="img-fluid" alt="Ford Strunk" title="Autohaus in Köln" />
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Beratung & Konzeption</p>
                  </li>
                  <li>
                    <p>
                      Erstellung einer Internetpräsenz</p>
                  </li>
                </ul>
              </div>

            </div>
            <!--/.Card-->

            <!--LHAW -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Hemp Society</h4>
                <!-- Price -->
                <img src="assets/hempsociety.jpg" class="img-fluid" alt="P.P.Meinhold Immobilien"
                  title="Immobilienmakler in München" />
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Erstellung eines erfolgreichen OnlineShops</p>
                  </li>
                  <li>
                    <p>
                      Suchmaschinenoptimierung</p>
                  </li>
                  <li>
                    <p>Online Marketing</p>
                  </li>

                </ul>
              </div>

            </div>
            <!--/.Card-->

          </div>
          <!--./Card Deck-->

          <br />

          <div class="card-deck">

            <!--Altusried Card-->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.2s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">E-Roller Vergleich
                </h4>
                <!-- Price -->
                <a href="https://www.eroller-vergleich.de/" target="_blank" title="E-Roller Vergleich">
                  <img src="assets/eroller-vergleich.jpg" class="img-fluid" alt="E-Roller Vergleich"
                    title="Vergleich von Elektrorollern" /></a>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Aufbau einer Affiliate Marketing Webseite</p>
                  </li>

                </ul>
              </div>
            </div>
            <!--/.Card-->

            <!--Hemp Society -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">Motorrad-Nebensaison</h4>
                <!-- Price -->
                <a href="https://www.motorrad-nebensaison.de/" target="_blank" title="Motorrad-Nebensaison">
                  <img src="assets/motorrad-nebensaison.jpg" class="img-fluid" alt="Motorrad-Nebensaison"
                    title="Motorradbekleidung, Gepäck & Zubehör im Herbst, Winter & Frühling" /></a>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>
                      Beratung & Konzeption</p>
                  </li>
                  <li>
                    <p>
                      Erstellung einer Internetpräsenz</p>
                  </li>
                </ul>
              </div>

            </div>
            <!--/.Card-->

            <!--LHAW -->
            <div class="card pricing-card shadow wow slideInUp" data-wow-delay="0.6s">

              <!--Card content-->
              <div class="card-body">
                <!--Title-->
                <h4 class="card-title font-weight-bold mt-3">HelloKaffee</h4>
                <!-- Price -->
                <a href="https://hellokaffee.de/" target="_blank" title="Hello Kaffee">
                  <img src="assets/hellokaffee.png" class="img-fluid" alt="Hello Kaffee OnlineShop"
                    title="Hello Kaffee OnlineShop für Kaffeebohnen und Barista-Zubehör" /></a>
                <!--Text-->

                <ul class="list-unstyled striped title">
                  <li>
                    <p>Erstellung eines OnlineShops</p>
                  </li>
                  <li>
                    <p>Suchmaschinenoptimierung</p>
                  </li>

                </ul>
              </div>

            </div>
            <!--/.Card-->

          </div>
          <!--./Card Deck-->
        </div>
      </div>
    </section>
  </div>
</div>