<div class="view withoutBackgroundPicture">

  <div class="container">

    <section class="content-section">

      <div class="row">

        <div class="col-lg-12">

          <!-- Heading -->
          <h1>Kontakt</h1>

          <p>Senden Sie mir jetzt eine Anfrage. Ich freue mich bereits auf Ihre Nachricht und werde mich bald bei Ihnen
            melden!</p>
          <p>Herzlichst Ihr Patrick Wenzke-Consulting</p>
          <form id="contactform" (ngSubmit)="processForm()" #contactForm="ngForm">

            <!-- Name -->
            <div class="form-group">
              <label for="name">Name</label>

              <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
                #name="ngModel">
              <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
                Name ist notwendig
              </div>
            </div>

            <!-- E-Mail -->
            <div class="form-group">
              <label for="mail">E-Mail</label>
              <input type="email" class="form-control" id="emailInput" required [(ngModel)]="model.mail" name="mail"
                email #mail="ngModel">
              <!--<div [hidden]="mail.valid || mail.pristine" class="alert alert-danger">
                E-Mail is required
              </div>-->
              <div *ngIf="mail.errors?.email" class="alert alert-danger">
                E-Mail Adresse nicht gültig
              </div>
            </div>

            <!-- Message -->
            <div class="form-group">
              <label for="message">Nachricht</label>
              <textarea class="form-control" id="messageInput" rows="12" [(ngModel)]="model.message" name="message"
                #message="ngModel"></textarea>
              <div [hidden]="message.valid || message.pristine" class="alert alert-danger">Nachricht benötigt</div>
            </div>

            <p>
              Beim Absenden erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens gespeichert und
              verarbeitet werden und Sie stimmen der Datenschutzerklärung zu. Weitere Informationen und Hinweise zum
              Datenschutz, finden Sie in der Datenschutzerklärung.</p>

            <button type="submit" class="btn btn-primary" [disabled]="!contactForm.form.valid">Senden</button>

            <div *ngIf="successTransport; else sendError" class="alert alert-success">Erfolgreich verschickt</div>
            <div *ngIf="errorWhileTransport" class="alert alert-danger wow fadeIn">Problem beim Versand</div>

          </form>

        </div>

      </div>

    </section>

  </div>

</div>