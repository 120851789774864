import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-jira',
  templateUrl: './jira.component.html',
  styleUrls: ['./jira.component.css']
})

@Injectable()
export class JiraComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, private meta: Meta, private title: Title) {

    // set title and meta information
    this.meta.addTag({ name: 'description', content: 'Projekt- & Aufgabenmanagement mit Jira Software von Wenzke-Consulting. ✓ Installation ✓ Einführung & Betreuung ✓ Prozesse ✓ Workflows ✓ Reports' });
    this.meta.addTag({ name: 'author', content: 'Patrick Wenzke' });
    this.meta.addTag({ name: 'keywords', content: 'Jira, Jira Software, Jira Betreuung, Jira Support, Atlassian, Projektmanagement, Aufgabenmanagement, Workflows, Bug Tracking, Reports' });
    
    // Open Graph Meta information
    this.meta.addTag({ property: 'og:locale', content: 'de_DE' });
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: 'Jira Software - Installation, Einführung & Betreuung' });
    this.meta.addTag({ property: 'og:description', content: 'Projekt- & Aufgabenmanagement mit Jira Software von Wenzke-Consulting. ✓ Installation ✓ Einführung & Betreuung ✓ Prozesse ✓ Workflows ✓ Reports' });
    this.meta.addTag({ property: 'og:site_name', content: 'Wenzke-Consulting' });
    this.meta.addTag({ property: 'og:url', content: 'https://www.wenzke-consulting.de/jira' });
    this.meta.addTag({ property: 'og:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });

    // Twitter Meta information
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@wenzkeconsult' });
    this.meta.addTag({ name: 'twitter:title', content: 'Jira Software - Installation, Einführung & Betreuung' });
    this.meta.addTag({ name: 'twitter:description', content: 'Projekt- & Aufgabenmanagement mit Jira Software von Wenzke-Consulting. ✓ Installation ✓ Einführung & Betreuung ✓ Prozesse ✓ Workflows ✓ Reports' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://www.wenzke-consulting.de/assets/og.jpg' });
    
    // Website title
    this.title.setTitle("Jira Software - Installation, Einführung & Betreuung");

    // Canonical Tag
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);

    // Apple Touch Icon
    let appleTouchIcon: HTMLLinkElement = this.doc.createElement('link');
    appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
    this.doc.head.appendChild(appleTouchIcon);
    appleTouchIcon.setAttribute('href', window.location.host + "/assets/apple-touch-icon.png");

  }

  priceBasic: number = 500;
  pricePro: number = 750;
  pricePremium: number = 1500;

  numberOfProjectsBasic: number = 1;
  numberOfUsersBasic: number = 10;
  numberOfWorkflowsBasic: number = 2;

  ngOnInit() {

    // Scroll to top after link click
    window.scroll(0, 0);

  }

  myEvent(event) {
    console.log(event);
  }

  scroll(el) {
    el.scrollIntoView();
  }



}
