import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  host: {
    '(window:scroll)': 'onScroll($event)'
  }
})
export class NavbarComponent implements OnInit {

  constructor() { }

  isScrolled: boolean = false;
  currPos: Number = 0;
  startPos: Number = 0;
  changePos: Number = 100;
  mouseHoveredHome;
  mouseHoveredJira;
  mouseHoveredSeo;
  mouseHoveredSoftware;
  mouseHoveredResponsiveDesign;
  mouseHoveredMarketing;
  mouseHoveredProjectmgmt;
  mouseHoveredShops;
  mouseHoveredAnfrage;

  onScroll(event) {

    this.currPos = (window.pageYOffset || event.target.scrollTop) - (event.target.clientTop || 0);

    if (this.currPos >= this.changePos) {
      this.isScrolled = true;
    }
    else {
      this.isScrolled = false;
    }

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}
