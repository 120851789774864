<nav class="navbar navbar-expand-lg navbar-dark nav-pwc fixed-top shadow scrolling-navbar" [ngClass]="{'page-scroll-header': isScrolled}">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/navbar-logo.png" class="img-fluid" [ngClass]="{'image-scroll-header': isScrolled}" alt="Wenzke-Consulting"
        title="Wenzke-Consulting">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredHome" (mouseover)="mouseHoveredHome=true"
          (mouseout)="mouseHoveredHome=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#">Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredJira" (mouseover)="mouseHoveredJira=true"
          (mouseout)="mouseHoveredJira=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="jira" title="JIRA Software">Jira</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredShops" (mouseover)="mouseHoveredShops=true"
          (mouseout)="mouseHoveredShops=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="shops" title="Onlineshop erstellen lassen">Onlineshops</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredResponsiveDesign" (mouseover)="mouseHoveredResponsiveDesign=true"
          (mouseout)="mouseHoveredResponsiveDesign=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="responsive-design" title="Responsive Design">Responsive Design</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredSeo" (mouseover)="mouseHoveredSeo=true" (mouseout)="mouseHoveredSeo=false"
          data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link text-uppercase" routerLink="seo" title="Seo">Seo</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredSoftware" (mouseover)="mouseHoveredSoftware=true"
          (mouseout)="mouseHoveredSoftware=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="software-entwicklung" title="Software-Entwicklung">Software-Entwicklung</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredMarketing" (mouseover)="mouseHoveredMarketing=true"
          (mouseout)="mouseHoveredMarketing=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="online-marketing" title="Online-Marketing">Online-Marketing</a>
        </li>
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredProjectmgmt" (mouseover)="mouseHoveredProjectmgmt=true"
          (mouseout)="mouseHoveredProjectmgmt=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="it-projektmanagement" title="IT-Projektmanagement">IT-Projektmanagement</a>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li [routerLinkActive]="['active']" class="nav-item" [class.hover]="mouseHoveredAnfrage" (mouseover)="mouseHoveredAnfrage=true"
          (mouseout)="mouseHoveredAnfrage=false" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link border border-light rounded waves-effect waves-light" routerLink="kontakt" title="Kontaktformular">
            <i class="far fa-envelope"></i>
            Anfrage
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>