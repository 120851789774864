<!--Shops Intro-->
<div id="shops-intro" class="view">

    <div class="mask rgba-black-strong">

        <div class="container-fluid d-flex align-items-center justify-content-center h-100">

            <div class="row d-flex justify-content-center text-center">

                <div class="col-lg-12">

                    <!-- Heading -->
                    <h1 class="display-4 font-weight-bold white-text pt-5 mb-2">Onlineshop erstellen lassen</h1>

                    <!-- Divider -->
                    <hr class="hr-light">

                    <!-- Description -->
                    <h4 class="white-text my-4">Verkaufen Sie ihre Produkte Online.</h4>

                    <button type="button" class="btn btn-outline-white" (click)="scroll(target)">Erfahren Sie mehr
                        <i class="fas fa-graduation-cap"></i>
                    </button>

                </div>

            </div>

        </div>

    </div>

</div>
<!--/.Shops Intro-->
<main>

    <div class="entry-wrap pt-5 pb-5">

        <div class="container">

            <section #target>

                <!--First Row-->
                <div class="row wow fadeIn">

                    <!--First Column-->
                    <div class="col-md-6">
                        <h2>Onlineshop erstellen lassen
                        </h2>
                        <p>
                            Öffnen Sie sich der digitalen Welt.
                        </p>
                        <ul class="icon-list">
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Keine Öffnungszeiten -
                                <i>Ihr Shop hat rund um die Uhr geöffnet</i>.
                            </li>
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Keine geografische Grenze -
                                <i>Sie können nicht nur im Ladengeschäft, sondern weltweit verkaufen</i>.
                            </li>
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Viele Versand- & Zahlungsarten -
                                <i>Flexibel auf Kundenwünsche reagieren</i>.
                            </li>
                        </ul>
                        <p>
                            Profitieren Sie von der E-Commerce Welt und nutzen sie die Chancen, ihre Produkte nicht nur
                            im Ladengeschäft zu verkaufen.
                        </p>
                    </div>
                    <!--./First Column-->

                    <!--Second Column-->
                    <div class="col-md-5 col-md-offset-1">
                        <img src="assets/shop-entwicklung.jpg" alt="Onlineshop erstellen lassen"
                            title="Onlineshop erstellen lassen" class="img-fluid shadow">
                    </div>
                    <!--./Second Column-->

                </div>
                <!--./First Row-->

                <!--Second Row-->
                <div class="row wow fadeIn mt-5">

                    <!--First Column-->
                    <div class="col-lg-6">
                        <img src="assets/shop-laptop.jpg" alt="Software-Entwicklung Android"
                            title="Software-Entwicklung Android" class="img-fluid shadow">
                    </div>
                    <!--./First Column-->

                    <!--Second Column-->
                    <div class="col-lg-6">
                        <h2>Es ist <b>leicher als Sie denken</b>
                        </h2>
                        <p>
                            Nehmen Sie Bestellungen online entgegen, empfangen den Betrag direkt auf das gewünschte
                            Konto und verschicken die Ware an den Kunden.
                        </p>
                        <ul class="icon-list">
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Ich erstelle Ihren Onlineshop nach ihren individuellen Wünschen
                            </li>
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Sie erhalten eine Einweisung in die Bedienung des Shopsystems
                            </li>
                            <li>
                                <i class="fa fa-check green-text mt-2"></i>
                                Sie sparen Zeit und Geld
                            </li>
                        </ul>

                    </div>
                    <!--./Second Column-->

                </div>
                <!--./Second Row-->

            </section>

        </div>

    </div>

    <div class="container">

        <section class="mt-5 mb-5">

            <!--First Row-->
            <div class="row">

                <!--First Column-->
                <div class="col-lg-12 wow fadeIn">
                    <h2>Warum brauche ich einen Onlineshop?</h2>
                    <p>Die Vorteile des Onlinehandels liegen auf der Hand:</p>
                    <h3>Kein geografisches Gebundensein</h3>
                    <p>
                        Mit einem eigenen Onlineshop erhalten Sie Zugang zum Weltmarkt.
                        Ein Ladengeschäft ist immer ortsgebunden. Sie befreien sich von dieser geografischen Grenze.
                        Ihre Kunden erfreuen sich daran, nicht mehr extra aus dem Haus zu müssen und sparen sich gerne
                        die Zeit.
                    </p>
                    <h3>Öffnungszeiten</h3>
                    <p>Im Gegensatz zu einem Ladengeschäft müssen Sie und ihre Kunden sich nicht an gesetzliche
                        Öffnungszeiten halten. Kunden können 24 Stunden rund um die Uhr bei Ihnen bestellen, ganz ohne
                        Hektik und Stress. Diese Eigenschaft ist für Kunden einer der wichtigsten Vorteile beim
                        Onlinekauf.</p>
                    <h3>Verschiedene Zahlungsarten</h3>
                    <p>Bieten Sie ihrem Kunden doch einfach die Möglichkeit, via PayPal, Kreditkarte, Vorkasse oder
                        weiteren Zahlungsarten die Rechnung zu begleichen. Jeder Kunde kann so für sich die passende
                        Zahlungsart auswählen. So viel Flexibilität kann kaum ein Ladengeschäft bieten.</p>
                    <h3>Kostenersparnis</h3>
                    <p>Der Betrieb eines Ladengeschäfts ist immer mit immensen Investitionen verbunden. Neben Miete,
                        Nebenkosten und Personal fallen viele weitere monatliche Kosten an. Einen Onlineshop zu betreiben
                        ist deutlich kostengünstiger. Inzwischen sind Onlineshops zu einem Muss geworden. </p>
                    <h3>Neue Kunden & Vermarktung</h3>
                    <p>Sie werden staunen, welche neuen Wege sich Ihnen öffnen. Nicht nur ein äußerlich gepflegtes
                        Ladengeschäft lädt Kunden zum Eintreten ein. Auch ein gepflegter Onlineshop wird gerne besucht.
                        Ich berate Sie gerne, welche Möglichkeiten ihnen zur Verfügung stehen, um Internetnutzer auf
                        diesen aufmerksam zu machen.
                    </p>

                </div>
                <!--./First Column-->

            </div>
            <!--./First Row-->
            <!-- Call to action -->
            <div class="row">
                <div class="col-md-5 p-lg-3 mx-auto my-3">

                    <a class="btn btn-indigo btn-ml waves-effect waves-light shadow ml-0"
                        href="https://wenzke-consulting.atlassian.net/servicedesk/customer/portal/1/group/1/create/17"
                        target="_blank" title="Onlineshop Anfrage">Ich will
                        einen Onlineshop
                        <i class="fas fa-arrow-right ml-1"></i>
                    </a>

                </div>
            </div>

        </section>

    </div>

</main>